// OutputProductParameters, ProductParametersForm
import { Trans } from '@lingui/macro';
import { i18nMark } from '@lingui/react';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';

// import Accordion from '@geobank/components/src/common/Accordion/Accordion';
// import ProductCodeField from './fields/ProductCodeField';
// import SrsNameField from './fields/SrsNameField';
// import BytesPerPixelField from './fields/BytesPerPixelField';
import HelpIcon from 'components/common/HelpIcon/HelpIcon';
import SelectField from 'components/forms/SelectField';

import { RootState } from 'ducks';
import { getCurrentCartParams, updateCurrentCartParams } from 'ducks/order';

import styles from './ProductParametersForm.module.scss';

interface ProductParametersFormProps {
  data: { [key: string]: string | number };
  onChange: (data: any) => void;
  instrument: string;
}

const mapStateToProps = (state: RootState) => ({
  params: getCurrentCartParams(state),
});
const mapDispatchToProps = {
  updateCurrentCartParamsRequest: updateCurrentCartParams.request,
};

class ProductParametersForm extends PureComponent<
  ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & ProductParametersFormProps
> {
  private handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    // @ts-ignore
    // this.props.updateCurrentCartParamsRequest({ [fieldName]: value });

    this.props.onChange({ ...this.props.data, [fieldName]: value });
  };

  public getBytesPerPixelOptions = () => {
    const options = [{ value: '-', label: '-' }];
    switch (this.props.instrument) {
      case 'PSS':
      case 'MSS':
      case 'MSUIKSRM':
        // options = [{ value: 1, label: '1' }]; // select returns value as string
        options.push({ value: '2', label: '2' });
        break;
      case 'AVR':
      case 'IRS':
      case 'LISS3':
      case 'LISS4':
      case 'MUX':
      case 'MUXNAD':
      case 'PANMUX':
      case 'GSA':
      case 'WFV':
      case 'WFI':
      case 'PMS':
      case 'BWD':
      case 'MSU101,MSU102':
        options.push({ value: '2', label: '2' });
        break;
      case 'GTN':
      case 'SVR':
      case 'SSR':
      case 'GTNL1':
        options.push(
          ...[
            { value: '2', label: '2' },
            { value: '1', label: '1' },
          ]
        );
        break;
    }
    return options;
  };

  public render() {
    const {
      // params: { productCode, srsName, bytesPerPixel },
      data: { productCode, srsName, bytesPerPixel },
    } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.paramTitle}>
          <span>
            <Trans>Информационный продукт</Trans>
            <Tooltip
              tagName="span"
              styles={{ marginLeft: '6px' }}
              tipContentClassName="tip-content--border-grey tip-content--infoproduct"
              arrow={false}
              background="white"
              color="black"
              direction="bottom"
              // content={i18n._(t`L1 - Нетрансформированный с RPC`)}
              content={
                <Trans>
                  L1 - Снимок с координатной привязкой RPC, не трансформированный в картографическую
                  проекцию <br />
                  L2 - Снимок с координатной привязкой RPC, трансформированный в картографическую
                  проекцию
                </Trans>
              }
            >
              <HelpIcon />
            </Tooltip>
          </span>
        </div>
        <div className={styles.paramField}>
          {/* <ProductCodeField name="productCode" value={productCode} onChange={this.handleChange} /> */}
          <SelectField
            name="productCode"
            value={productCode}
            options={[
              { value: 'L1', label: i18nMark('L1 - Нетрансформированный с RPC') },
              { value: 'L2', label: i18nMark('L2 - В картографической проекции') },
            ]}
            onChange={this.handleChange}
          />
        </div>
        <div className={styles.paramContainer}>
          <div className={styles.width_50}>
            <div className={styles.paramTitle}>
              <span>
                <Trans>Система координат</Trans>
              </span>
            </div>
            <div className={styles.paramField}>
              {/* <SrsNameField name="srsName" value={srsName} onChange={this.handleChange} /> */}
              <SelectField
                name="srsName"
                value={srsName}
                options={[
                  { value: '4326', label: i18nMark('WGS 84') },
                  { value: '4284', label: i18nMark('Pulkovo 1942') },
                  { value: '7683', label: i18nMark('GSK-2011') },
                ]}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className={styles.width_50}>
            <div className={styles.paramTitle}>
              <span title="Байт / пикс. кан. (GeoTIFF)">
                <Trans>Байт / пикс. кан.</Trans> {/* (GeoTIFF) */}
              </span>
            </div>
            <div className={styles.paramField}>
              {/* <BytesPerPixelField name="bytesPerPixel" value={bytesPerPixel} onChange={this.handleChange} /> */}
              <SelectField
                name="bytesPerPixel"
                value={bytesPerPixel}
                // options={[
                //   { value: '1', label: '1' },
                //   { value: '2', label: '2' },
                // ]}
                options={this.getBytesPerPixelOptions()}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
        {/* <div className={styles.paramTitle}>
          <span>
            <Trans>Спектральные каналы</Trans>
          </span>
        </div>
        <div className={styles.paramField}>
          ---
        </div> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductParametersForm);
