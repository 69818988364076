import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import { Trans } from '@lingui/macro';
import React from 'react';
import { useSelector } from 'react-redux';

import { formatNumber } from 'components/utils/format';
import { RootState } from 'ducks';
import styles from './SearchResultStatusBar.module.scss';

interface SearchResultStatusbarProps {
  isFetchingImages: boolean;
  limit: number;
  offset: number;
  onLoadMore: () => void;
}

const SearchResultStatusbar: React.FC<SearchResultStatusbarProps> = props => {
  const { isFetchingImages, limit, offset, onLoadMore } = props;
  const currentResultsCount = useSelector((state: RootState) => state.metadata.results.length);
  const totalResultsCount = useSelector((state: RootState) => state.metadata.totalImages);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = React.useState<boolean>(
    offset + limit < totalResultsCount || isFetchingImages
  );

  React.useEffect(() => {
    setIsLoadMoreDisabled(offset + limit > totalResultsCount || isFetchingImages);
  }, [isFetchingImages, limit, offset, totalResultsCount]);

  const handleLoadMore = () => {
    onLoadMore();
  };

  return (
    <div className={styles.root}>
      <div className={styles.statusBarInfo}>
        <span className={styles.statusBarInfoValue}>
          <Trans>Снимков</Trans>
        </span>
        <span className={styles.statusBarInfoValue}>{formatNumber(currentResultsCount)}</span>
        <span className={styles.statusBarInfoValue}>
          <Trans>из</Trans>
        </span>
        <span className={styles.statusBarInfoValue}>{formatNumber(totalResultsCount)}</span>
      </div>
      <Button
        className={styles.statusBarButton}
        color={ButtonColor.TRANSPARENT}
        onClick={handleLoadMore}
        disabled={isLoadMoreDisabled}
      >
        <Trans>Показать еще</Trans>
      </Button>
    </div>
  );
};

export default SearchResultStatusbar;
