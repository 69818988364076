import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import Loader from '@geobank/components/src/common/Loader/Loader';
// import { RadioChangeEvent } from 'components/common/forms/Radio/interface';
import { Trans } from '@lingui/macro';
import { RootState } from 'ducks';
import {
  changeActiveItemIndex,
  // changeStartTime
} from 'ducks/stacItems';
import { STACItem } from 'ducks/types/stacItemsTypes';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
// import DownloadImagesPanel from './DownloadImagesPanel';
// import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
// import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import Tooltip from 'react-tooltip-lite';
import SettingsPanel from './SettingsPanel';
import STACItemsDatePicker from './STACItemsDatePicker';
import styles from './STACItemsLayer.module.scss';

// const equals = (a: STACItem[], b: STACItem[]) => JSON.stringify(a) === JSON.stringify(b);

const STACItemsLayer: React.FC = () => {
  const dispatch = useDispatch();
  const itemsDates: string[] = useSelector((state: RootState) => state.stacItems.itemsDates);
  const items: STACItem[] = useSelector((state: RootState) => state.stacItems.items);
  // const selectedLayerId: string = useSelector(
  //   (state: RootState) => state.layers.selectedImageLayerId
  // );
  const activeItemIndex: number | undefined = useSelector(
    (state: RootState) => state.stacItems.activeItemIndex
  );
  const maxIndex: number = items.length - 1;
  // const [itemIndex, setItemIndex] = useState<number>(activeItem);
  // useEffect(() => {
  //   dispatch(changeStartTime.request());
  // });
  // const arcticAnimate = useSelector((state: RootState) => state.layers.animateArcticM);
  // const [isPlaying, setIsPlaying] = useState<boolean | undefined>(arcticPlay.isPlaying);
  // const [direction, setDirection] = useState<string | undefined>(arcticPlay.direction);
  // const [delay, setDelay] = useState<number | undefined>(arcticPlay.delay);
  // const [step, setStep] = useState<number | undefined>(arcticPlay.step);

  // const handlePlayClick = () => {
  //   setIsPlaying(!isPlaying);
  // };

  // useEffect(() => {
  //   dispatch(animateArcticM({ direction: arcticAnimate.direction }));
  // }, [dispatch, arcticAnimate.direction]);

  // useEffect(() => {
  //   dispatch(animateArcticM({ isAnimate: arcticAnimate.isAnimate }));
  // }, [dispatch, arcticAnimate.isAnimate]);

  // useEffect(() => {
  //   dispatch(animateArcticM({ step: arcticAnimate.step }));
  // }, [dispatch, arcticAnimate.step]);

  // useEffect(() => {
  //   dispatch(animateArcticM({ delay: arcticAnimate.delay }));
  // }, [dispatch, arcticAnimate.delay]);

  // useEffect(() => {
  //   dispatch(changeActiveItem(activeItem));
  //   dispatch(changeStartTime.request());
  // }, [dispatch, activeItem]);

  const handleNextClick = () => {
    activeItemIndex > 0
      ? dispatch(changeActiveItemIndex(activeItemIndex - 1))
      : dispatch(changeActiveItemIndex(maxIndex));
  };

  const handlePreviousClick = () => {
    activeItemIndex < maxIndex
      ? dispatch(changeActiveItemIndex(activeItemIndex + 1))
      : dispatch(changeActiveItemIndex(0));
  };

  // const handleDirectionChange = (event: RadioChangeEvent) => {
  //   const value = event.target.value;
  //   setDirection(value);
  // };

  // const handleIncreaseDelay = () => {
  //   if (delay) {
  //     setDelay(delay + 1000);
  //   }
  // };

  // const handleDecreaseDelay = () => {
  //   if (delay && delay > 1000) {
  //     setDelay(delay - 1000);
  //   }
  // };

  // const handleStepChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const value = event.target.value;
  //   setStep(Number(value));
  // };

  return (
    <div className={styles.panelContainer}>
      {/* <Tooltip
          tagName="span"
          styles={{ marginLeft: '4px' }}
          tipContentClassName="tip-content--border-grey"
          arrow={false}
          background="white"
          color="black"
          direction="up"
          // distance={160}
          content={
            <div>
              <Trans>Воспроизведение снимков</Trans>
            </div>
          }
        >
          <Button
            onClick={handlePlayClick}
            color={ButtonColor.TRANSPARENT}
            style={{ width: '40px', height: '46px' }}
          >
            {!arcticPlay.isPlaying ? (
              arcticPlay.direction === 'forward' ? (
                <PlayCircleOutlineIcon sx={{ color: '#5dbdf9' }} fontSize="medium" />
              ) : (
                <PlayCircleOutlineIcon
                  sx={{ color: '#5dbdf9' }}
                  fontSize="medium"
                  style={{ transform: 'rotate(180deg)' }}
                />
              )
            ) : (
              <StopCircleOutlinedIcon sx={{ color: '#5dbdf9' }} fontSize="medium" />
            )}
          </Button>
        </Tooltip> */}
      <Tooltip
        tagName="span"
        styles={{ marginLeft: '4px' }}
        tipContentClassName="tip-content--border-grey"
        arrow={false}
        background="white"
        color="black"
        direction="up"
        content={
          <div>
            <Trans>Предыдущий снимок</Trans>
          </div>
        }
      >
        <Button
          color={ButtonColor.TRANSPARENT}
          onClick={handlePreviousClick}
          style={{ width: '40px', height: '46px' }}
          disabled={items.length === 0}
        >
          <FiArrowLeft size={'28px'} color={'#5dbdf9'} strokeWidth={'2px'} />
        </Button>
      </Tooltip>
      <Tooltip
        tagName="span"
        styles={{ marginLeft: '4px' }}
        tipContentClassName="tip-content--border-grey"
        arrow={false}
        background="white"
        color="black"
        direction="up"
        content={
          <div>
            <Trans>Следующий снимок</Trans>
          </div>
        }
      >
        <Button
          color={ButtonColor.TRANSPARENT}
          onClick={handleNextClick}
          style={{ width: '40px', height: '46px' }}
        >
          <FiArrowRight size={'28px'} color={'#5dbdf9'} strokeWidth={'2px'} />
        </Button>
      </Tooltip>
      <STACItemsDatePicker
        activeItem={items[activeItemIndex]}
        activeDates={itemsDates}
        onIncreaseTimeClick={handleNextClick}
        onDecreaseTimeClick={handlePreviousClick}
      />
      {items.length > 0 && (
        <SettingsPanel
          activeItem={items[activeItemIndex]}
          // delay={arcticPlay.delay}
          // direction={arcticPlay.direction}
          // isPlaying={arcticPlay.isPlaying}
          // step={arcticPlay.step}
          // onDirectionChange={handleDirectionChange}
          // onIncreaseDelay={handleIncreaseDelay}
          // onDecreaseDelay={handleDecreaseDelay}
          // onStepChange={handleStepChange}
        />
      )}
    </div>
  );
};

export default STACItemsLayer;
