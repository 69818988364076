// import TileLayer from 'ol/layer/Tile';
import TileLayer from 'libs/ol/layer/Tile'; // , { TileLayerOptions }
import XYZ from 'ol/source/XYZ';

import { CreateLayerBundle } from '../types';

const createSimpleXYZLayer = (bundle: CreateLayerBundle) => {
  const { id, name, options } = bundle; // params
  const layer = new TileLayer({
    id,
    name,
    extent: options.extent,
    preload: Infinity,
    // -- /custom args delimiter --
    visible: options.visible || options.visibility || false,
    source: new XYZ({
      url: options.url,
      urls: options.urls,
      transition: 0,
      attributions: options.attribution,
      // maxZoom: options.maxZoom,
      // minZoom: options.minZoom,
      // maxResolution: options.maxResolution,
      tileSize: options.tileSize,
      // crossOrigin: '*',
    }),
    zIndex: options.zIndex,
    maxZoom: options.maxZoom,
    minZoom: options.minZoom,
  });
  return layer;
};

export default createSimpleXYZLayer;
