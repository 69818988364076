import { Trans } from '@lingui/macro';
import { i18nMark } from '@lingui/react';
// import { css } from 'astroturf';
// import { connect } from 'react-redux';
// import { RootState } from 'ducks';
import dateFormat from 'date-fns/format';
import React, { PureComponent } from 'react';

import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import { snakeToCamel } from '@geobank/assets/src/uibook/helpers/utils';
// import IconButton from '@geobank/components/src/common/IconButton/IconButton';

import { formatNumber } from 'components/utils/format';
import { /* removeFilter, */ searchImages as searchMetadata } from 'ducks/metadata';
import { isEmptyValue } from 'utils/values';

import styles from './SearchParamsCompact.module.scss';

// const searchParamsMap_old = {
//   dateBegin: 'Дата начала',
//   dateEnd: 'Дата окончания',
//   maxCloudiness: 'Облачность (макс)',
// };
// Object:
// - key
// - title
// - pattern | render: (value) => `до ${value}%`
const searchParamsMap = {
  acquisitionDateAfter: {
    title: i18nMark('Дата начала'),
    renderValue: (value: Date) => `${dateFormat(value, 'd.MM.yyy')}`, // d-MM-yyy
  },
  acquisitionDateBefore: {
    title: i18nMark('Дата окончания'),
    renderValue: (value: Date) => `${dateFormat(value, 'd.MM.yyy')}`, // d-MM-yyy
  },
  cloudinessMax: {
    title: i18nMark('Облачность'),
    renderValue: (value: number) => <Trans>до {value} %</Trans>,
  },
  //
  instrumentIdentifiers: {
    title: i18nMark('Съёмочная аппаратура'),
    renderValue: (value: string[], onRemove: (item: string) => void) => {
      return value.map(item => (
        <span style={{ display: 'inline-block', marginLeft: '8px' }} key={item}>
          {item}
          <span
            className={styles.cross}
            // onClick={this.handleRemoveFilter.bind(null, item)}
            onClick={() => onRemove(item)}
          >
            <CrossIcon />
          </span>
        </span>
      ));
    },
  },
};

// const getParamTitle = (key: string) => {
//   // @ts-ignore
//   return searchParamsMap[key] ? searchParamsMap[key] : key;
// };

// tslint:disable-next-line:no-empty-interface
interface SearchParamsCompactProps {
  params: any;
  onEditParams: () => void;
  // removeFilter: typeof removeFilter;
  searchMetadataRequest: typeof searchMetadata.request;
  // count: number;
  total: number;
}

// tslint:disable-next-line:no-empty-interface
interface SearchParamsCompactState {}

class SearchParamsCompact extends PureComponent<
  SearchParamsCompactProps,
  SearchParamsCompactState
> {
  public state = {};

  public handleRemoveFilter = (key: string, val?: any) => {
    // v1
    // this.props.removeFilter(key);
    // this.props.searchMetadataRequest(null);
    // v2
    const newParams = { ...this.props.params };
    if (Array.isArray(newParams[key]) && newParams[key].length >= 1 && val !== undefined) {
      const valIndex = newParams[key].indexOf(val);
      newParams[key].splice(valIndex, 1);
    } else {
      delete newParams[key];
    }
    this.props.searchMetadataRequest(newParams);
  };

  public render() {
    const { params, onEditParams, total } = this.props;

    return (
      <>
        <div className={styles.root}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <Trans>
                <span>Результат:</span>
              </Trans>
              <Trans>
                <span
                  className={styles.countImages}
                  title={total >= 10000 ? 'Найдено более 10 000 снимков' : undefined}
                >
                  {formatNumber(total)} снимков
                </span>
              </Trans>
              {/* TODO - нет снимков */}
            </div>
          </div>
          <div>
            {Object.keys(params)
              .filter(
                // item => ['dateBegin', 'dateEnd', 'maxCloudiness'].indexOf(snakeToCamel(item)) > -1,
                item =>
                  !isEmptyValue(params[item]) &&
                  Object.keys(searchParamsMap).indexOf(snakeToCamel(item)) > -1
              )
              .map(item => {
                // @ts-ignore
                const { title, renderValue } = searchParamsMap[item];
                return (
                  <div key={item} className={styles.dataListItem}>
                    {/* <div className={styles.dataTitle}>{getParamTitle(snakeToCamel(item))}</div> */}
                    <div className={styles.dataTitle}>
                      {/* {title} */}
                      <Trans id={title} />
                    </div>
                    <div className={styles.dataValue}>
                      {/* {params[item]} */}
                      {/* item !== 'instrumentIdentifiers' */}
                      {Array.isArray(params[item]) ? (
                        renderValue(params[item], (val: string) =>
                          this.handleRemoveFilter(item, val)
                        )
                      ) : (
                        <>
                          {renderValue(params[item])}
                          <span
                            className={styles.cross}
                            onClick={this.handleRemoveFilter.bind(null, item)}
                          >
                            <CrossIcon />
                          </span>
                        </>
                      )}
                    </div>
                    {/* <IconButton><CrossIcon /></IconButton> */}
                  </div>
                  // <p key={item}>
                  //   {item} - {params[item]}
                  // </p>
                );
              })}
            <span className={styles.linkAction} onClick={onEditParams}>
              <Trans>редактировать параметры</Trans>
            </span>
          </div>
        </div>
      </>
    );
  }
}

// connect для получения доступа к action - RemoveFilter (RemoveSearchFilter / searchFilter.remove)
export default SearchParamsCompact;
/* export default connect(
  // ({ metadata }: store.RootState) => ({ metadata }),
  (state: RootState) => ({
    // isFetching: getIsFetching(state),
  }),
  {
    searchMetadataRequest: searchMetadata.request,
    // removeFilter,
  }
)(SearchParamsCompact); */
