import { t, Trans } from '@lingui/macro'; // Trans
import { withI18n, withI18nProps } from '@lingui/react';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'react-tooltip-lite';

// import Checkbox from '@geobank/components/src/forms/CheckBox/Checkbox';
import CartIcon from '@geobank/assets/src/icons/CartIcon';
import DuplicateIcon from '@geobank/assets/src/icons/DuplicateIcon';
import TrashIcon from '@geobank/assets/src/icons/TrashIcon';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import Checkbox, { CHECKED, UNCHECKED } from './CheckBox/Checkbox';

import { cutFileIdentifier } from 'components/utils/format';
import {
  canOrderImageByInstrument,
  canOrderOperativeImageAndGetDiffByDate,
} from 'components/utils/order';
import { ImageMetadataDetail } from 'ducks/types/metadataTypes';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { FiClock } from 'react-icons/fi';

import { RootState } from 'ducks';
import styles from './SearchResultItemHeader.module.scss';

const tooltipProps = {
  tipContentClassName: 'tip-content--border-grey', // tip-content--searchheaderactions
  arrow: false,
  direction: 'down',
  hoverDelay: 10,
  padding: '8px',
  background: '#fce9a4',
  color: 'black',
};

interface SearchResultItemHeaderProps {
  data: ImageMetadataDetail; // ImageMetadata

  isActive: boolean;
  isChecked: boolean;
  isInCart: boolean;

  onActivate: (md: ImageMetadataDetail) => void; // (id: number) => void;
  onToggleCheck: (md: ImageMetadataDetail) => void;
  onCopyToClipboard: (text: string) => void;
  onAddToCart: (md: ImageMetadataDetail) => void;
  onRemove: (md: ImageMetadataDetail) => void;
}

const SearchResultItemHeader: React.FC<SearchResultItemHeaderProps & withI18nProps> = props => {
  const { data, isChecked, isInCart, i18n } = props;

  const payOrderAllowed = useSelector((state: RootState) => state.config.paymentAllowed);
  const freeUser = useSelector((state: RootState) => state.iam.user.profile.isFree);

  const handleActivate = () => {
    props.onActivate(data);
  };

  const handleCopyToClipboard = () => {
    props.onCopyToClipboard(data.identifier);
  };

  const handleAddToCart = () => {
    props.onAddToCart(data);
  };

  const handleRemove = () => {
    props.onRemove(data);
  };

  const handleCheck = () => {
    props.onToggleCheck(data);
  };

  const [canOrderOperativeImage, diffDays] = canOrderOperativeImageAndGetDiffByDate(
    data.acquisitionDateBegin || data.acquisitionDateInstant
  );

  const canOrderImage: boolean = payOrderAllowed
    ? canOrderImageByInstrument(data.instrumentIdentifier, data.platformIdentifier)
    : freeUser;

  return (
    <div className={styles.root}>
      <Checkbox
        name="md:777"
        value={isChecked ? CHECKED : UNCHECKED}
        onChange={handleCheck}
        disabled={!canOrderImage || !canOrderOperativeImage}
      />
      <span className={styles.label}>
        <>
          <span
            className={styles.itemLabelContainer}
            // onDoubleClick={this.handleSelectRoute.bind(this, item)}
            onClick={handleActivate}
          >
            {/* <span
              className={classNames(styles.itemLabel, {
                [styles.itemLabelActive]: swipeImage.includes(data.identifier),
              })}
            > */}
            <span className={classNames(styles.itemLabel)}>
              {cutFileIdentifier(data.identifier, 1)}
            </span>
          </span>
          <div className={styles.itemHeadActions}>
            <Tooltip
              {...tooltipProps}
              content={
                <Trans>
                  Скопировать идентификатор <br />
                  {data.identifier}
                </Trans>
              }
            >
              <Button
                className={classNames(styles.itemHeadActionButton)}
                color={ButtonColor.TRANSPARENT}
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleCopyToClipboard();
                }}
              >
                <DuplicateIcon fill="currentColor" size={10} />
              </Button>
            </Tooltip>
            {canOrderImage && canOrderOperativeImage ? (
              <Tooltip
                {...tooltipProps}
                content={<Trans id="image_actions.add_to_cart">Добавить в корзину</Trans>}
              >
                <Button
                  color={ButtonColor.TRANSPARENT}
                  className={classNames(styles.itemHeadActionButton, styles.cartButton, {
                    active: isInCart, // currentCartImagesIds.indexOf(item.identifier) > -1,
                  })}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleAddToCart();
                  }}
                >
                  <CartIcon fill="currentColor" />
                </Button>
              </Tooltip>
            ) : // ) : (
            canOrderImage && !canOrderOperativeImage ? (
              <Tooltip
                {...tooltipProps}
                content={
                  diffDays === 30 ? (
                    <Trans id="image_actions.add_to_cart_op_blocked2">
                      Этот снимок станет доступным для заказа в течение 24 часов
                    </Trans>
                  ) : (
                    i18n._(
                      t(
                        'image_actions.add_to_cart_op_blocked'
                      )`Этот снимок станет доступным для заказа через {restDays} {restDays, plural, one {день} few {дня} many {дней} other {дней}}`
                        .id,
                      { restDays: 30 - diffDays }
                    )
                  )
                }
              >
                <FiClock size={'20px'} color={'#d2d2d2'} strokeWidth={'2px'} />
              </Tooltip>
            ) : (
              <Tooltip
                {...tooltipProps}
                content={
                  <Trans id="image_actions.add_to_cart_blocked">Заказ снимка не доступен</Trans>
                }
              >
                <FiClock size={'20px'} color={'#d2d2d2'} strokeWidth={'2px'} />
              </Tooltip>
            )}
            <Tooltip
              {...tooltipProps}
              content={i18n._(t('image_actions.remove_from_results')`Удалить из результатов`)}
            >
              <Button
                color={ButtonColor.TRANSPARENT}
                className={classNames(styles.itemHeadActionButton, styles.removeButton)}
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleRemove();
                }}
              >
                <TrashIcon fill="currentColor" />
              </Button>
            </Tooltip>
          </div>
        </>
      </span>
    </div>
  );
};

export default withI18n()(SearchResultItemHeader);
