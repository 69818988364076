import { t, Trans } from '@lingui/macro';
import { withI18n, withI18nProps } from '@lingui/react';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
// import Tooltip from 'react-tooltip-lite';

import ContourIcon from '@geobank/assets/src/icons/ContourIcon';
import EyeIcon from '@geobank/assets/src/icons/EyeIcon';
import FileDownloadIcon from '@geobank/assets/src/icons/FileDownloadIcon';
import PictureIcon from '@geobank/assets/src/icons/PictureIcon';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
// import BaseSwipeImage from '@geobank/digitalearth/src/components/ToolPanel/BaseSwipeImage/BaseSwipeImage';
// import OpacityControl from '@geobank/digitalearth/src/components/ToolPanel/OpacityControl/OpacityControl';
// import GeoportalMap from 'components/map/Map/Map';
import { ImageMetadataDetail } from 'ducks/types/metadataTypes';

import { RootState } from 'ducks';
import { getActiveImageFullResolutions, getMetadata } from 'ducks/metadata';
// import { Layer } from 'ol/layer';
// import { BandCombinationType } from '../../../../../ducks/types/metadataTypes';
import styles from './SearchResultItemFooter.module.scss';

const sortOrder = ['pan', 'ms'];

const getIsActive = (activeIds: string[], id: string) => {
  return activeIds.includes(id);
};

export const isActiveFullResolution = (activeFullResolutionIds: string[], id: string) => {
  const activeIds = activeFullResolutionIds.map(activeId => {
    const splitedId = activeId.split('.');
    return splitedId.splice(0, splitedId.length - 1).join('.');
  });
  return activeIds.includes(id);
};

interface SearchResultItemFooterProps {
  data: ImageMetadataDetail; // ImageMetadata
  isContourActive: boolean;
  isPreviewActive: boolean;

  onMetadataDownload: (md: ImageMetadataDetail) => void;
  onToggleContour: (md: ImageMetadataDetail) => void; // (item: ImageMetadataDetail) => void;
  onTogglePreview: (md: ImageMetadataDetail) => void;
  onToggleFullImage: (ids: string[]) => void;
}

const SearchResultItemFooter: React.FC<SearchResultItemFooterProps & withI18nProps> = props => {
  const {
    data,
    i18n,
    onMetadataDownload,
    onToggleContour,
    onTogglePreview,
    onToggleFullImage,
  } = props;

  // const [fullResLayer, setFullReslayer] = React.useState<Layer>();
  const [fullRoute, setFullRoute] = React.useState<any[]>([]);
  const imageSource = useSelector((state: RootState) => state.metadata.searchParams.sourceType);
  const results = useSelector((state: RootState) => getMetadata(state));
  const bandCombinations: typeof data.coverage = Object.values(
    data.coverage.reduce((acc, obj) => ({ ...acc, [obj.bandCombination]: obj }), {})
  );

  const activeImagesFullResolution = useSelector((state: RootState) =>
    getActiveImageFullResolutions(state)
  );

  const handleToggleContour = () => {
    onToggleContour(data);
  };

  const handleTogglePreview = () => {
    onTogglePreview(data);
  };

  React.useEffect(() => {
    if (data.identifier.startsWith('APOI')) {
      const routeIdentifier = data.identifier.split('.').slice(0, -1).join('.');
      const routeScenes = results.filter(img => img.identifier.startsWith(routeIdentifier));
      setFullRoute(routeScenes);
    }
  }, [data, results]);

  // React.useEffect(() => {
  //   if (isActiveFullResolution(activeImagesFullResolution, data.identifier)) {
  //     const map = GeoportalMap.getInstance();
  //     setFullReslayer(map.getSwipeImageLayer([data], 'ms')[0] as Layer);
  //   }
  // }, [activeImagesFullResolution, data]);

  // const handleToggleFullImage = (isMultispectral: boolean, isFullRoute: boolean) => {
  //   isFullRoute
  //     ? props.onToggleFullImage(fullRoute, true)
  //     : props.onToggleFullImage([data], isMultispectral);
  // };
  const handleToggleFullImage = (id?: string, isFullRoute?: boolean) => {
    isFullRoute
      ? onToggleFullImage(fullRoute.map(scene => `${scene.identifier}.ms`))
      : onToggleFullImage([id!]);
  };

  const handleMetadataDownload = () => {
    onMetadataDownload(data);
  };

  return (
    <>
      <div className={styles.listItemFooter}>
        <div>
          <div className={styles.test11}>
            <Trans>Карта:</Trans>
          </div>
          <div className={styles.test11}>
            <Button
              color={ButtonColor.TRANSPARENT}
              className={classNames(styles.mapActionButton, {
                active: props.isContourActive,
              })}
              title={i18n._(t('image_actions.contour')`Контур`)}
              onClick={handleToggleContour}
            >
              <ContourIcon fill="currentColor" />
            </Button>
          </div>
          {data.previews.length > 0 && imageSource !== 'esmdp2' && (
            <div className={styles.test11}>
              <Button
                color={ButtonColor.TRANSPARENT}
                className={classNames(styles.mapActionButton, {
                  active: props.isPreviewActive,
                })}
                title={i18n._(t('image_actions.preview_image')`Обзорное изображение`)}
                onClick={handleTogglePreview}
              >
                <PictureIcon fill="currentColor" />
              </Button>
            </div>
          )}
          {bandCombinations &&
            bandCombinations.length > 0 &&
            bandCombinations
              ?.sort(
                (a, b) =>
                  sortOrder.indexOf(a.bandCombination) - sortOrder.indexOf(b.bandCombination)
              )
              .map(image => (
                <div key={`${data.identifier}.${image.bandCombination}`} className={styles.test11}>
                  <Button
                    color={ButtonColor.TRANSPARENT}
                    className={classNames(styles.mapActionButton, {
                      active: getIsActive(
                        activeImagesFullResolution,
                        `${data.identifier}.${image.bandCombination}`
                      ),
                    })}
                    title={
                      image.bandCombination === 'ms'
                        ? i18n._(
                            t(
                              'image_actions.full_resolution_image_multispectral_image'
                            )`Снимок в полном разрешении (мультиспектральное изображение)`
                          )
                        : i18n._(
                            t(
                              'image_actions.full_resolution_image_panchromatic_image'
                            )`Снимок в полном разрешении (панхроматическое изображение)`
                          )
                    }
                    onClick={() =>
                      handleToggleFullImage(`${data.identifier}.${image.bandCombination}`)
                    }
                  >
                    <EyeIcon fill={image.bandCombination === 'ms' ? 'currentColor' : '#393939'} />
                  </Button>
                </div>
              ))}
        </div>
        <div>
          <div className={styles.test11}>
            <Trans>Метаданные:</Trans>
          </div>
          <div className={styles.test11}>
            <Button
              color={ButtonColor.TRANSPARENT}
              className={classNames(styles.mapActionButton)}
              title={i18n._(t('image_actions.metadata_download')`Скачать метаданные`)}
              onClick={handleMetadataDownload}
            >
              <FileDownloadIcon />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withI18n()(SearchResultItemFooter);
