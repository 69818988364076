import { Trans } from '@lingui/macro';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import Button from '@geobank/components/src/common/Button/Button';
import IconButton from '@geobank/components/src/common/IconButton/IconButton';

import { RootState } from 'ducks';
import { geometryFavoriteActions } from 'ducks/favorites';
import { balloonToggle, getBalloonData, getIsBalloonShow, getRegionsOfInterest } from 'ducks/map';
import ImageMetadataBalloon from './items/ImageMetadataBalloon';

import styles from './MapBalloon.module.scss';

// interface MapBalloonProps {
//   data: any;
//   regionsOfInterest: any[];
//   isShow: boolean;
//   balloonToggle: typeof balloonToggle;

//   addGeometryFavorite: typeof geometryFavoriteActions.addRequest; // add
// }
const mapStateToProps = (state: RootState) => ({
  regionsOfInterest: getRegionsOfInterest(state),
  isShow: getIsBalloonShow(state),
  data: getBalloonData(state),
});
const mapDispatchToProps = {
  balloonToggle,
  addGeometryFavorite: geometryFavoriteActions.addRequest,
};

class MapBalloon extends PureComponent<
  ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
> {
  public static defaultProps = {
    data: {},
  };

  public handleClose = () => {
    this.props.balloonToggle(false, undefined); // (false, {})
  };

  public handleBookmark = () => {
    const { regionsOfInterest } = this.props;
    const currentRegion = regionsOfInterest[0];

    if (currentRegion) {
      // localStorage.setItem('regions', JSON.stringify([currentRegion]));
      this.props.addGeometryFavorite(currentRegion);
    }
  };

  public render() {
    const { data } = this.props;
    if (!this.props.isShow) {
      return null;
    }

    // const { regionsOfInterest } = this.props;
    // const currentRegion = regionsOfInterest[0] || {};

    if (data.metadata_id) {
      return <ImageMetadataBalloon data={data} onClose={this.handleClose} />;
    }

    return (
      <div className={styles.root}>
        <div className={styles.body}>
          <div className={styles.header}>
            <h3>
              <Trans>Район интереса</Trans>
            </h3>
            <IconButton onClick={this.handleClose /* () => onClose() */}>
              <CrossIcon />
            </IconButton>
          </div>
          {/* <div>
            {currentRegion.properties &&
              Object.entries<string>(currentRegion.properties).map(
                ([key, value], index) =>
                  !key.startsWith('_') &&
                  value && (
                    <div className={styles.dataListItem} key={index}>
                      <div className={styles.dataTitle}>{key}</div>
                      <div className={styles.dataValue}>{value}</div>
                    </div>
                  )
              )}
          </div> */}
        </div>
        <div className={styles.footer}>
          <Button width="100%" onClick={this.handleBookmark}>
            <Trans>Добавить в закладки</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

// export default MapBalloon;
export default connect(
  // (state: store.RootState) => ({
  //   regionsOfInterest: getRegionsOfInterest(state),
  //   isShow: getIsBalloonShow(state),
  // }),
  mapStateToProps,
  // {
  //   balloonToggle,
  //   addGeometryFavorite: geometryFavoriteActions.addRequest, // add
  // }
  mapDispatchToProps
)(MapBalloon);
