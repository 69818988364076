import * as turf from '@turf/turf';
// import nanoid from 'nanoid/non-secure';
import { genId } from 'components/utils/identifier';
import GeoJSON from 'ol/format/GeoJSON';

import { SearchResultItem } from 'ducks/mapObjects';

// parse data from `gptl` provider
export const parseSearchByPortalResults = (data: any) => {
  const results: SearchResultItem[] = [];
  try {
    const format = new GeoJSON();
    if (data.images) {
      const imageFeatures = format.readFeatures(data.images);
      imageFeatures.forEach(item => {
        // platform	Ресурс-П1
        // metadata_id	10208828
        // date_instant	2020-01-22T06:12:55Z
        // scan_number	2
        // circuit_number	6960
        const properties = item.getProperties();
        results.push({
          id: genId(6),
          // // Ресурс-П1 2020-01-22T06:12:55Z виток 6960, маршрут 2 (10208828)
          title: `${properties.platform} ${properties.date_instant} виток ${properties.circuit_number} маршрут ${properties.scan_number} (id ${properties.metadata_id})`,
          type: 'gptl-images',
          geometry: properties.geometry,
          data: properties, // TODO - del geometry
        });
      });
    }
    if (data.layers) {
      const layerFeatures = format.readFeatures(data.layers);
      layerFeatures.forEach(item => {
        // name: 'Москва'
        // layer: 'Столицы'
        const properties = item.getProperties(); // item.getGeometry()
        results.push({
          id: genId(6),
          title: `${properties.name} (${properties.layer})`,
          type: 'gptl-layers',
          geometry: properties.geometry,
        });
      });
    }
  } catch (err) {
    /* results = []; */
  }
  return results;
};

// parse data from `osm` provider
export const parseSearchObjectsByOSMResults = (data: any) => {
  const results: SearchResultItem[] = [];
  try {
    data.forEach((item: any) => {
      results.push({
        id: genId(6),
        // Москва, Центральный федеральный округ, Россия
        title: `${item.display_name}`,
        type: 'osm',
        geometry: item.geojson,
        // data: {},
      });
    });
  } catch (err) {
    /* results = []; */
  }
  return results;
};

// parse data from `yandex` provider
export const parseSearchObjectsByYandexResults = (data: any) => {
  const results: SearchResultItem[] = [];
  try {
    data.forEach((item: any) => {
      results.push({
        id: genId(6),
        title: `${item.GeoObject.metaDataProperty.GeocoderMetaData.text}`,
        type: 'yandex',
        geometry: bboxFromYandexCoordinates(
          item.GeoObject.boundedBy.Envelope.lowerCorner
            .split(' ')
            .map((coord: any) => Number(coord)),
          item.GeoObject.boundedBy.Envelope.upperCorner
            .split(' ')
            .map((coord: any) => Number(coord))
        ),
      });
    });
  } catch (err) {
    console.error('error parse yandex', err);
    /* results = []; */
  }
  return results;
};

export const bboxFromYandexCoordinates = (cornerA: number[], cornerB: number[]) => {
  const pointA = turf.point(cornerA);
  const pointB = turf.point(cornerB);
  const boundingBox = turf.bbox(turf.featureCollection([pointA, pointB]));
  const polygon = turf.bboxPolygon(boundingBox); // convert it to Polygon feature
  return polygon.geometry;
};
