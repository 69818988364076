import { ActionType, getType } from 'deox';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'; // debounce // fork,

import {
  balloonToggle,
  drawControl,
  /*drawControl, */ getGeofenceGeometry,
  setShapeGeometry,
} from 'ducks/map';
import { searchObjects } from 'ducks/mapObjects';
import get from 'lodash/get';
import requestFlow from './network';

import { i18nMark } from '@lingui/react';
import * as turf from '@turf/turf';
import API from 'api';
import {
  parseSearchByPortalResults,
  parseSearchObjectsByOSMResults,
  parseSearchObjectsByYandexResults,
} from 'api/helpers';
import GeoportalMap from 'components/map/Map/Map';
import { formatArea, geoJSONToWKTAndTransform } from 'components/map/Map/utils';
import {
  getCroppingPolygonIntersection,
  isValidGeofenceIntersection,
} from 'components/utils/validation/checkIntersections';
import { getIsAuthenticated, getIsGeofenced } from 'ducks/authIAM';
import { getSelectedBaseLayerId } from 'ducks/mapLayers';
import { notifyActions } from 'ducks/message';
// import { isValidGeofenceIntersection } from 'components/utils/validation/checkIntersections';
import {
  changeSearchParams,
  getImageSourceType,
  searchImages,
  toggleImageContour,
} from 'ducks/metadata';
import { SourceType } from 'ducks/types/metadataTypes';
import GeoJSON from 'ol/format/GeoJSON';
// import { formatArea } from '../components/map/DrawControl/DrawControl';
import { fetchBbpResource, fetchEtrisResource, fetchStacResource } from './metadata';

// TODO - вынесли список провайдеров и их приоритет по отображению
type SearchObjectsProvider = 'osm' | 'gptl' | 'gptl_proxy' | 'yandex';

const kvPSSSensor = {
  number: 1,
  instrumentIdentifier: 'PSS',
  id: 2,
  bands: [
    {
      min: 0.54,
      max: 0.86,
      description: 'Панхром',
      id: 30,
    },
  ],
  isPanchromatic: true,
};

interface ISearchArchiveByIdParams {
  identifier: string;
}

export function* searchObjectsWatch() {
  yield takeLatest(getType(searchObjects.request), searchObjectsFlow); // что это даёт getType? - работает и без него
}

export function* searchMosaicObjectFlow(params: ISearchArchiveByIdParams) {
  const stacResult: any = yield call(fetchStacResource, { ids: params.identifier });
  if (stacResult) {
    stacResult.results = stacResult.results.map((md: any) =>
      md.instrumentIdentifier === 'MSS' ? md.sensors.unshift(kvPSSSensor) && md : md
    );
  }
  yield put(searchObjects.success({ name: 'stac', results: stacResult }));
  const data = {
    count: get(stacResult, 'count', 0),
    results: [...get(stacResult, 'results', [])],
  };
  const map = GeoportalMap.getInstance();
  yield call([map, 'addMetadataResults'], data.results, false);

  yield put(searchImages.success({ count: data.count, results: data.results }));
}

export function* searchArchiveObjectFlow(params: ISearchArchiveByIdParams) {
  const [etrisResult, bbpResult, stacResult]: [any, any, any] = yield all([
    params.identifier.startsWith('ETRIS') ? call(fetchEtrisResource, params) : null,
    params.identifier.startsWith('BBP') ? call(fetchBbpResource, params) : null,
    params.identifier.startsWith('ETRIS')
      ? call(fetchStacResource, { ids: params.identifier }, 'stac-archive')
      : null,
  ]);
  if (etrisResult) {
    etrisResult.results = etrisResult.results.map((md: any) =>
      md.instrumentIdentifier === 'MSS' ? md.sensors.unshift(kvPSSSensor) && md : md
    );
  }
  yield put(searchObjects.success({ name: 'etris', results: etrisResult }));
  yield put(searchObjects.success({ name: 'bbp', results: bbpResult }));
  yield put(searchObjects.success({ name: 'stac', results: stacResult }));

  const data = {
    count: get(bbpResult, 'count', 0) + get(etrisResult, 'count', 0) + get(stacResult, 'count', 0),
    results: [
      ...get(bbpResult, 'results', []),
      ...get(etrisResult, 'results', []),
      ...get(stacResult, 'results', []),
    ],
  };
  const count = data.count;
  const results = data.results;

  const map = GeoportalMap.getInstance();
  yield call([map, 'addMetadataResults'], results, false); // false // resData.data

  yield put(searchImages.success({ count, results }));
  const resultsIds: string[] = results.map(result => result.identifier);
  yield put(toggleImageContour.success(resultsIds, true));
}

export function* searchObjectsFlow(action: ActionType<typeof searchObjects.request>) {
  try {
    const query = action.payload;
    const isAuthenticated: boolean = yield select(getIsAuthenticated);
    const imageSourcetype: SourceType = yield select(getImageSourceType);
    const selectedBaselayerId: string = yield select(getSelectedBaseLayerId);
    const searchCatalogParams = { identifier: query };
    // debounce by 1000ms
    yield delay(1000);
    if (query.startsWith('ETRIS.') || query.startsWith('BBP.')) {
      const isGeofenced: boolean = yield select(getIsGeofenced);
      if (isAuthenticated) {
        // const [etrisResult, bbpResult]: [any, any] = yield all([
        //   call(fetchResource, searchCatalogParams, 'etris'),
        //   call(fetchResource, searchCatalogParams, 'bbp'),
        // ]);
        const [etrisResult, bbpResult]: [any, any] = yield all([
          searchCatalogParams.identifier.startsWith('ETRIS')
            ? call(fetchEtrisResource, searchCatalogParams)
            : null,
          searchCatalogParams.identifier.startsWith('BBP')
            ? call(fetchBbpResource, searchCatalogParams)
            : null,
        ]);
        if (etrisResult) {
          // prettier-ignore
          etrisResult.results = etrisResult.results.map((md: any) =>
            md.instrumentIdentifier === 'MSS' ? md.sensors.unshift(kvPSSSensor) && md : md
          );
        }
        yield put(searchObjects.success({ name: 'etris', results: etrisResult }));
        yield put(searchObjects.success({ name: 'bbp', results: bbpResult }));
        const data = {
          count: get(bbpResult, 'count', 0) + get(etrisResult, 'count', 0),
          results: [...get(bbpResult, 'results', []), ...get(etrisResult, 'results', [])],
        };
        const count = data.count;
        const results = data.results;

        let isValid: boolean = true;

        if (isGeofenced) {
          const geofenceGeometry = yield select(getGeofenceGeometry);
          isValid =
            getCroppingPolygonIntersection(
              geoJSONToWKTAndTransform(geofenceGeometry[0]),
              data.results[0].geometry
            ) !== null;
        }
        if (isValid) {
          const map = GeoportalMap.getInstance();
          yield call([map, 'addMetadataResults'], results, false); // false // resData.data
          yield put(searchImages.success({ count, results }));
          const resultsIds: string[] = results.map(result => result.identifier);
          yield put(toggleImageContour.success(resultsIds, true));
        } else {
          yield put(
            notifyActions.push({
              timeout: 4000,
              color: 'alert',
              message: i18nMark(
                'Геометрия снимка находится за пределами района ограничения, заданного Оператором системы!'
              ),
              place: 'bc',
            })
          );
        }
      } else {
        yield put(
          notifyActions.push({
            timeout: 4000,
            color: 'info',
            message: i18nMark('Для поиска снимка по идентификатору необходимо авторизоваться'),
            place: 'bc',
          })
        );
        yield put(searchObjects.failure('Not authenticated'));
      }
    } else if (imageSourcetype === 'esmdp2' && query.startsWith('APOI.')) {
      yield call(searchMosaicObjectFlow, searchCatalogParams);
    } else {
      if (selectedBaselayerId === 'Yandex') {
        const yandexResult: any = yield call(fetchGeocodeResource, query, 'yandex');
        yield put(searchObjects.success({ name: 'yandex', results: yandexResult }));
      } else {
        const [osmResult, gptlResult]: [any, any] = yield all([
          call(fetchGeocodeResource, query, 'osm'),
          call(fetchGeocodeResource, query, 'gptl'),
        ]);

        // const data = {
        //   count: get(osmResult, 'count', 0) + get(gptlResult, 'count', 0),
        //   results: [
        //     ...get(osmResult, 'results', []),
        //     ...get(gptlResult, 'results', []),
        //   ],
        // };
        yield put(searchObjects.success({ name: 'osm', results: osmResult }));
        yield put(searchObjects.success({ name: 'gptl', results: gptlResult }));
      }
      // yield put(searchObjects.success({ name: 'osm', results: osmResult }));
    }
  } catch (error) {
    // console.error('searchObjectsFlow error', error);
  }
}

function* fetchGeocodeResource(query: string, provider: SearchObjectsProvider) {
  try {
    let results;
    switch (provider) {
      case 'yandex': {
        const data = yield call(requestFlow, API.searchObjectsByYandex, query);
        results = parseSearchObjectsByYandexResults(
          data.response.GeoObjectCollection.featureMember
        );
        break;
      }
      case 'osm': {
        const data = yield call(requestFlow, API.searchObjectsByOSM, query);
        results = parseSearchObjectsByOSMResults(data);
        break;
      }
      case 'gptl': {
        const data = yield call(requestFlow, API.searchByPortal, query);
        results = parseSearchByPortalResults(data.results);
        break;
      }
      case 'gptl_proxy':
      default:
        throw Error('Provider not found!');
    }
    // yield put(searchObjects.success({ name: provider, results }));
    return results;
  } catch (error) {
    yield put(searchObjects.failure(provider));
  }
}

// select object on map
export function* selectObjectWatch() {
  yield takeLatest(getType(searchObjects.select), selectObjectFlow);
}
export function* selectObjectFlow(action: ActionType<typeof searchObjects.select>) {
  try {
    const { item: searchObject, isZoomNeed } = action.payload;
    const map = GeoportalMap.getInstance();
    // yield call([map, 'centerMapViewOnSearchObject'], searchObject);
    const isAuthenticated: boolean = yield select(getIsAuthenticated);
    const isGeofenced: boolean = yield select(getIsGeofenced);
    let validGeometry: boolean = searchObject.geometry.type === 'Polygon';
    let searchFeature: any;
    searchFeature = new GeoJSON().writeFeatureObject(
      new GeoJSON().readFeature(searchObject.geometry)
    );
    if (validGeometry) {
      searchObject.geometry.coordinates.length = 1;
      searchFeature = new GeoJSON().writeFeatureObject(
        new GeoJSON().readFeature(searchObject.geometry)
      );
      const { output, measure } = formatArea(
        map.readGeometry(searchObject.geometry, 'GeoJSON').getGeometry()
      );
      searchFeature.properties = { _type: 'region', area: output, measure: measure };
      if (isGeofenced) {
        const geofenceGeometry: ReturnType<typeof getGeofenceGeometry> = yield select(
          getGeofenceGeometry
        );
        const geofenceCoordinates =
          geofenceGeometry.length > 0 && geofenceGeometry[0].geometry.type === 'Polygon'
            ? geofenceGeometry[0].geometry.coordinates
            : [];
        validGeometry = isValidGeofenceIntersection(
          searchObject.geometry.coordinates,
          geofenceCoordinates
        );
        const geofence = turf.polygon(
          geofenceGeometry[0].geometry.type === 'Polygon'
            ? geofenceGeometry[0].geometry.coordinates
            : []
        );
        const search = turf.polygon(searchObject.geometry.coordinates);
        const intersection = turf.intersect(search, geofence);
        if (intersection) {
          if (intersection.geometry.type === 'MultiPolygon') {
            yield call([map, 'addRegionOfInterestLayer'], geofenceGeometry[0], isZoomNeed);
            yield put(
              notifyActions.push({
                timeout: 5000,
                message: i18nMark(
                  'В качестве района интереса поддерживаются геометрии только типа "Polygon"!'
                ),
                place: 'bc',
                color: 'alert',
              })
            );
          } else {
            yield put(changeSearchParams({ regionSelection: 'draw' }));
            yield put(setShapeGeometry(intersection, true));
            yield put(
              notifyActions.push({
                timeout: 8000,
                color: 'success',
                message: i18nMark(
                  'Геометрия найденного объекта может быть использована в качестве района интереса'
                ),
                place: 'bc',
              })
            );
          }
        } else {
          yield call([map, 'addRegionOfInterestLayer'], geofenceGeometry[0], isZoomNeed);
          yield put(
            notifyActions.push({
              timeout: 5000,
              message: i18nMark(
                'Район интереса выходит за пределы ограничительного района, заданного Оператором системы!'
              ),
              place: 'bc',
              color: 'alert',
            })
          );
        }
      } else {
        yield put(changeSearchParams({ regionSelection: 'draw' }));
        yield put(setShapeGeometry(searchFeature, true));
        yield put(
          notifyActions.push({
            timeout: 8000,
            color: 'success',
            message: i18nMark(
              'Геометрия найденного объекта может быть использована в качестве района интереса'
            ),
            place: 'bc',
          })
        );
      }
    } else {
      yield call([map, 'addRegionOfInterestLayer'], searchFeature, isZoomNeed);
      yield put(
        notifyActions.push({
          timeout: 5000,
          message: i18nMark(
            'В качестве района интереса поддерживаются геометрии только типа "Polygon"!'
          ),
          place: 'bc',
          color: 'alert',
        })
      );
    }
    if (isAuthenticated && validGeometry) {
      yield put(drawControl.show());
      yield put(balloonToggle(true));
    }
  } catch (error) {
    // console.error('Error', error);
  }
}

export function* clearObjectsWatch() {
  yield takeLatest(getType(searchObjects.clear), clearObjectsFlow);
}
export function* clearObjectsFlow(action: ActionType<typeof searchObjects.clear>) {
  try {
    const map = GeoportalMap.getInstance();
    const isAuthenticated: boolean = yield select(getIsAuthenticated);
    yield call([map, 'clearObjects']);
    if (!isAuthenticated) {
      yield call([map, 'clearRegionsOfInterest']);
    }
    // прячем баллон при очистке рез-в поиска по строке
    yield put(balloonToggle(false));
  } catch (error) {
    // console.error('Error', error);
  }
}

export default function* mapLayersRoot() {
  yield all([searchObjectsWatch(), selectObjectWatch(), clearObjectsWatch()]);
}
