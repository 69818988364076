// import { css } from 'astroturf';
// import classNames from 'classnames';
// import SearchOptionContainer from '../Forms/CommonComponents/SearchOptionContainer/SearchOptionContainer';
// import SearchLine from '@geobank/components/src/forms/SearchLine/SearchLine';
// import 'react-day-picker/lib/style.css';
// import DateInput from '@geobank/components/src/forms/DateInput/DateInput';
// import NewRangeInput from '@geobank/components/src/forms/NewRangeInput/NewRangeInput';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import * as React from 'react';

// import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
// import CheckBoxGroup from '@geobank/components/src/forms/CheckBoxGroup/CheckBoxGroup';
// import ButtonTabs from '@geobank/components/src/common/ButtonTabs/ButtonTabs';

// import cameraIcon from '@geobank/assets/src/icons/tabMenu/CameraIcon';
// import folderIcon from '@geobank/assets/src/icons/tabMenu/FolderIcon';
// import loadIcon from '@geobank/assets/src/icons/tabMenu/LoadIcon';
// import monitorIcon from '@geobank/assets/src/icons/tabMenu/MonitorIcon';
// import nibIcon from '@geobank/assets/src/icons/tabMenu/NibIcon';

import styles from './LeftMenu.module.scss';
// import DateRangeFieldset from './SearchForm/DateRangeFieldset';
// import CloudinessFieldset from './SearchForm/CloudinessFieldset';

// import '@geobank/assets/src/styles/font.css';

// import { useSelector } from 'react-redux';
import * as store from 'ducks';
import { getIFetching as getIsAuthenticating, getIsAuthenticated, iamActions } from 'ducks/authIAM';
import { fetchPlatforms } from 'ducks/classifier';
import { /* drawControl, getRegionsOfInterest, */ getIsMainPanelShow } from 'ducks/map';
import {
  // getIsFetching,
  getMetadata,
  // getSearchParams,
  // searchImages,
  ImageMetadata,
} from 'ducks/metadata';
import { connect } from 'react-redux';

// import { camelToSnake } from '@geobank/assets/src/uibook/helpers/utils';
// import dateFormat from 'date-fns/format';
import CustomOrderImageForm from '../CustomOrderImageForm';
// import SearchForm from './SearchForm/SearchForm';
// import Loader from '@geobank/components/src/common/Loader/Loader';
// import WKT from 'ol/format/WKT';
// import GeoJSON from 'ol/format/GeoJSON';
// import GeoportalMap from 'components/map/Map/Map';
// import SearchResult from './SearchResult/SearchResult';
// import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';
// import { ImageMetadataDetail } from 'ducks/types/metadataTypes';
import SearchFormPanel from './SearchFormPanel';
import SearchResultPanel from './SearchResultPanel';
// const serializeValue = (value: any) => {
//   return value instanceof Date ? dateFormat(value, 'yyy-MM-dd') : value;
// };

// tslint:disable-next-line:no-empty-interface
interface LeftMenuProps {
  metadata: ImageMetadata[];
  fetchPlatformsRequest: typeof fetchPlatforms.request;
  isMainPanelShow: boolean;
  doLogin: typeof iamActions.login;
  doRegister: typeof iamActions.register;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
}

// type PanelName = 'params' | 'results';

interface LeftMenuState {
  currentPanelName: 'params' | 'results' | 'custom';
  imageAddToCart?: ImageMetadata;

  // dateBegin?: Date;
  // dateEnd?: Date;
  // cloudinessMax?: number;
}

class LeftMenu extends React.Component<LeftMenuProps, LeftMenuState> {
  // private formRef: React.RefObject<HTMLInputElement>;

  // constructor({ props }: { props: any }) { -> Argument of type 'typeof LeftMenu' is not assignable to parameter of type 'ComponentType<never>'.
  constructor(props: LeftMenuProps) {
    super(props);
    this.state = {
      // dateInputValue: '',
      // rangeInputValue: [10, 20],
      currentPanelName: 'params',

      // dateBegin: undefined,
      // dateEnd: undefined,
      // cloudinessMax: undefined,
    };

    // this.formRef = React.createRef();
  }

  public handleDoLogin = () => {
    this.props.doLogin({ popup: true });
  };

  public handleDoRegister = () => {
    this.props.doRegister('popup');
  };

  public componentDidUpdate(prevProps: LeftMenuProps) {
    if (prevProps.metadata !== this.props.metadata) {
      this.setState({ currentPanelName: 'results' });
    }
  }

  public componentDidMount() {
    this.props.fetchPlatformsRequest();

    document.addEventListener('show-add-image-form', this.handleHello);
  }

  public componentWillUnmount() {
    document.removeEventListener('show-add-image-form', this.handleHello);
  }

  public handleHello = (event: any) => {
    const md = event.detail as ImageMetadata;
    this.setState({ currentPanelName: 'custom', imageAddToCart: md });
  };

  public render() {
    // const { dateBegin, dateEnd, cloudinessMax } = this.state;
    // Invalid hook call. Hooks can only be called inside of the body of a function component.
    // const counter = useSelector(getIsFetching);
    const {
      // isFetching,
      metadata,
      // searchParams,
      isMainPanelShow,
      isAuthenticated,
      isAuthenticating,
    } = this.props;
    const { currentPanelName, imageAddToCart } = this.state;

    if (isAuthenticating) {
      return null;
    }
    if (!isAuthenticated && !isAuthenticating) {
      return (
        <div className={classNames(styles.root, { _show: isMainPanelShow })}>
          <div className={styles.helloText}>
            <span>
              <Trans>
                Для доступа к снимкам высокого разрешения необходимо{' '}
                <span className={styles.helloTextLink} onClick={this.handleDoLogin}>
                  войти
                </span>{' '}
                в аккаунт Геопортала Роскосмоса.
                <br />
                Нет аккаунта -{' '}
                <span className={styles.helloTextLink} onClick={this.handleDoRegister}>
                  зарегистрируйтесь
                </span>
                , это быстро.
              </Trans>
              {/* в конце бета-тестирования аккаунты будут удалены :slight_smile: */}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className={classNames(styles.root, { _show: isMainPanelShow })}>
        {currentPanelName === 'custom' && imageAddToCart && (
          <div>
            <CustomOrderImageForm
              orderImage={imageAddToCart}
              onCancel={() => this.setState({ currentPanelName: 'params' })}
            />
          </div>
        )}
        {metadata.length !== 0 ? <SearchResultPanel /> : <SearchFormPanel />}
        {/* {currentPanelName === 'params' && <SearchFormPanel />}
        {currentPanelName === 'results' && (
          <SearchResultPanel
            onPanelChange={(panelName: 'params' | 'results') =>
              this.setState({ currentPanelName: panelName })
            }
          />
        )} */}
      </div>
    );
  }
}

export default connect(
  (state: store.RootState) => ({
    metadata: getMetadata(state),
    isMainPanelShow: getIsMainPanelShow(state),
    isAuthenticated: getIsAuthenticated(state),
    isAuthenticating: getIsAuthenticating(state),
  }),
  {
    fetchPlatformsRequest: fetchPlatforms.request,
    doLogin: iamActions.login,
    doRegister: iamActions.register,
  }
)(LeftMenu);
