import React, { Component } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

// import HomePage from '../App/App';
import PersonalAreaPage_ from 'pages/account_/PersonalAreaPage/PersonalAreaPage';
import HomePage from 'pages/HomePage/HomePage';
import AppInfoPage from 'pages/MaintenancePages/AppInfoPage';
import SentryVerifyPage from 'pages/MaintenancePages/SentryVerifyPage';
import MultiRequestPage from 'pages/MultiRequestPage/MultiRequestPage';
import PaymentFailPage from 'pages/PaymentReturnPages/PaymentFailPage';
import PaymentSuccessPage from 'pages/PaymentReturnPages/PaymentSuccessPage';
import PersonalAreaPage from 'pages/PersonalAreaPage/PersonalAreaPage';

import MapComponent from 'components/map/Map/MapComponent';
import MapBalloon from 'components/map/MapBalloon/MapBalloon';
import MapControls from 'components/map/MapControls';
import { ym, YMInitializerWrapped } from 'modules/metrics/yandex';
import ErrorBoundary from 'pages/ErrorPages/ErrorBoundaryPage';
import NotFoundErrorPage from 'pages/ErrorPages/NotFoundErrorPage';
import MaintenanceModePage from 'pages/MaintenancePages/MaintenanceModePage';
import AppContainer from './AppContainer';
import PrivateRoute from './PrivateRoute';

class AppRouter extends Component<RouteComponentProps<{}>> {
  public componentDidMount() {
    this.props.history.listen(location => {
      const url = location.pathname;
      ym('hit', url);
    });
  }

  public render() {
    return (
      <AppContainer>
        <ErrorBoundary>
          <YMInitializerWrapped />
          <MaintenanceModePage />
          <MapComponent />
          <MapControls />
          {/* возможно, разместить баллон в другом компоненте (как и controls) */}
          <MapBalloon />

          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/requests/new" component={MultiRequestPage} />
            {/* <Route path="/account" component={PersonalAreaPage} /> */}
            {/* <Route
              path="/account/:subpage(profile|orders)/:orderNumber([\d\-]+)?"
              component={PersonalAreaPage}
            /> */}
            <PrivateRoute path="/account/:subpage(profile|orders)/:orderNumber([\d\-]+)?">
              <PersonalAreaPage />
            </PrivateRoute>
            <Route path="/payment-ok" component={PaymentSuccessPage} />
            <Route path="/payment-fail" component={PaymentFailPage} />
            <Route path="/account_" component={PersonalAreaPage_} />
            <Route path="/__version" component={AppInfoPage} />
            <Route path="/__monitor" component={SentryVerifyPage} />
            <Route component={NotFoundErrorPage} />
          </Switch>
        </ErrorBoundary>
      </AppContainer>
    );
  }
}

export default withRouter(AppRouter);
