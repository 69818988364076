import React, { Component } from 'react';
import { connect } from 'react-redux';

import { RootState } from 'ducks';
import { balloonToggle } from 'ducks/map';
import { fetchLayers, getBaseLayers, getIsFetching } from 'ducks/mapLayers';
import { getActiveImagePreviews, selectMetadataRoute, toggleImagePreview } from 'ducks/metadata';

import GeoportalMap from './Map';
// import PopupElement from './PopupElement/PopupElement';

import 'ol/ol.css';
import 'styles/ol.css';

import { Debugger } from 'utils/logging';
const debug = Debugger('MapCmp');

interface MapComponentProps {
  gMap?: GeoportalMap;
  createOptions?: object;
  // connect
  fetchLayersRequest: typeof fetchLayers.request;
  selectMetadataRoute: typeof selectMetadataRoute;
  toggleImagePreview: typeof toggleImagePreview.request;
  balloonToggle: typeof balloonToggle;
  activeImagePreviews: string[];
  isFetching: boolean;
  baseLayers: any[];
}

class MapComponent extends Component<MapComponentProps> {
  // static propTypes = {};

  private map: GeoportalMap; // any
  private readonly mapRef: React.RefObject<HTMLInputElement>;

  private currentFeatureNum: number;
  private currentFeatureIds: number[];

  constructor(props: any) {
    super(props);

    this.mapRef = React.createRef();

    // lastClick = [];
    this.currentFeatureNum = 0;
    this.currentFeatureIds = [];

    // moved from componentDidMount()
    this.map = this.props.gMap
      ? this.props.gMap
      : new GeoportalMap({ ...this.props.createOptions });
  }

  public componentDidMount() {
    // subscribe events
    // TODO - режимы: отключено, интерактив1, интерактив2, ...
    this.map.getOlMap().on('singleclick', event => {
      const features = this.map.getOlMap().getFeaturesAtPixel(event.pixel).reverse();
      debug.log('map features', features);

      if (features.length === 0) {
        // popupElement.innerHTML = '&nbsp;';
        // popupElement.style.display = 'none';
        return;
      }

      const featureIds = features.map(item => item.getId() as number); // TODO - могут быть и undefiner, string!?
      if (
        this.currentFeatureIds.sort().toString() === featureIds.sort().toString() &&
        featureIds.length > this.currentFeatureNum + 1
      ) {
        this.currentFeatureNum++;
      } else {
        this.currentFeatureNum = 0;
      }

      // test
      // todo - getId() для нарисованной фичи -> undefined
      const feature = features[this.currentFeatureNum];
      // this.props.gpMap.selectMetadataFeature(feature.getId());
      // exclude feature with id=drawGeometry
      // if (typeof feature.getId() !== 'number' && ) {
      if (feature.get('_type') === 'metadata' && feature.getId()) {
        this.props.selectMetadataRoute(feature.getId() as string, false);
        this.props.balloonToggle(false);
        this.props.toggleImagePreview([feature.getId() as string]);
      } else if (feature.get('_type') === 'gptl-images') {
        // this.props.selectMetadataRoute(feature.get('data').metadata_id as number, false);
        this.props.balloonToggle(true, feature.get('data'));
      } else if (feature.get('_type') === 'region') {
        this.props.balloonToggle(true);
      } else if (feature.get('_type') === 'object') {
        // const coordinate = this.map._original_getCoordinateFromPixel(event.pixel);
        // const coordinate = event.coordinate;
        // popupElement.style.display = 'block';
        // popupElement.innerHTML = 'Наименование объекта test 231312 123';
        // popupElement.innerHTML = feature.get('name') || 'Наименование объекта';
        // popup.setPosition(coordinate);
      } else {
        // popupElement.innerHTML = '&nbsp;';
      }

      // this.lastClick = event.pixel;
      this.currentFeatureIds = featureIds;
    });

    // const overlayContainer = document.getElementById('overlay-container')!;

    // const overlayLayer = new Overlay({
    //   element: overlayContainer,
    // });
    // this.map.getOlMap().addOverlay(overlayLayer);

    // this.map.getOlMap().on('click', event => {
    //   overlayLayer.setPosition(undefined);
    //   const overlayRegionName = document.getElementById('region-name')!;
    //   // const overlayRegionTagOrder = document.getElementById('region-tag-order')!;
    //   const overlayRegionArea = document.getElementById('region-area')!;
    //   this.map.getOlMap().forEachFeatureAtPixel(event.pixel, (feature, layer) => {
    //     const clickedCoordinate = event.coordinate;
    //     overlayRegionName.innerHTML = `${feature.getProperties().name}`;
    //     overlayRegionArea.innerHTML = `${feature.getProperties().area} ${feature.getProperties().measure}<sup>2</sup>`;
    //     overlayLayer.setPosition(clickedCoordinate);
    //     // overlayText.innerHTML = '<p>You clicked here</p>'
    //   });
    // });

    // this.map.getOlMap().on('postrender', () => {
    // });

    this.map.getOlMap().setTarget(this.mapRef.current!);
    this.map.ensureCurrentRegionLayer();

    if (!this.props.isFetching && this.props.baseLayers.length === 0) {
      this.props.fetchLayersRequest();
    }

    // const mousePositionControl = new MousePosition({
    //   coordinateFormat: coord => toStringHDMS(coord!),
    //   projection: 'EPSG:4326',
    //   target: 'mouse-position',
    //   className: 'ol-mouse-position--custom',
    //   undefinedHTML: '', // сохраняет последнее значение
    // });
    // mousePositionControl.setMap(this.map.getOlMap());
  }

  public componentWillUnmount() {
    this.map.getOlMap().setTarget(undefined);
  }

  public componentDidUpdate(prevProps: any) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.map.setBaseLayers(this.props.baseLayers);
      this.map.toggleBaseLayer('OpenStreetMap');
    }
  }

  public render() {
    return (
      <div
        className="ol-map"
        // ref={el => (this.mapRef = el)}
        ref={this.mapRef}
        style={{ height: '100vh', width: '100%' }}
      >
        {/* <div ref="popup" className="ol-popup" /> */}
        {/* <PopupElement /> */}
        {/* <BaseLayerControl baseLayers={baseLayers} active={this.map._currentBaseLayerId} /> */}
      </div>
    );
  }
}

export default connect(
  (state: RootState) => ({
    baseLayers: getBaseLayers(state),
    isFetching: getIsFetching(state),
    activeImagePreviews: getActiveImagePreviews(state),
  }),
  {
    fetchLayersRequest: fetchLayers.request,
    selectMetadataRoute,
    toggleImagePreview: toggleImagePreview.request,
    balloonToggle,
  }
)(MapComponent);
