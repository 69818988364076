import 'moment/locale/ru';
import 'react-day-picker/lib/style.css';

import { Trans } from '@lingui/macro';
import { RootState } from 'ducks';
import { fetchDates, fetchItems, getItemsDates } from 'ducks/stacItems';
import React, { useEffect, useState } from 'react';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-tooltip-lite';

import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import Dropdown from '@geobank/components/src/common/Dropdown/Dropdown';

import { STACItem } from 'ducks/types/stacItemsTypes';
import { isEmpty } from 'lodash';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import styles from './STACItemsDatePicker.module.scss';

interface STACItemsDatePickerProps {
  activeItem: STACItem;
  activeDates: string[];
  onIncreaseTimeClick: () => void;
  onDecreaseTimeClick: () => void;
}
const modifiersStyles = {
  selectedDay: {
    color: '#5dbdf9',
    backgroundColor: '#14306a',
  },
  enabledDays: {
    color: 'white',
    backgroundColor: '#5dbdf9',
  },
  outside: {
    color: '#BEBEBE',
    backgroundColor: 'white',
  },
};

const STACItemsDatePicker: React.FC<STACItemsDatePickerProps> = props => {
  const { activeItem, activeDates, onIncreaseTimeClick, onDecreaseTimeClick } = props;
  const dispatch = useDispatch();
  const currentLocale: string = useSelector((state: RootState) => state.locale.language);
  const locale = currentLocale === 'en' ? 'en-US' : 'ru-RU';
  const itemDates = useSelector((state: RootState) => getItemsDates(state));
  const [selectedDay, setSelectedDay] = useState<Date>(new Date());

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
    timeZoneName: 'long',
  };

  useEffect(() => {
    if (itemDates.length > 0) {
      setSelectedDay(new Date(itemDates[itemDates.length - 1]));
    }
  }, [itemDates]);

  const activeItemDate = !isEmpty(activeItem) ? new Date(Object.keys(activeItem)[0]) : new Date();
  const localeDate = activeItemDate.toLocaleString(locale, options).split(' ');
  const headerLocaleDate = `${localeDate[0]} ${localeDate[1]}`;
  const enabledDays = activeDates.map(date => new Date(date));
  const modifiers = {
    enabledDays: enabledDays,
    selectedDay: selectedDay,
  };

  const handleDayClick = (day: Date) => {
    setSelectedDay(day);
    dispatch(fetchItems.request(day.toISOString()));
  };

  const handleMonthChangeClick = (month: Date) => {
    dispatch(fetchDates.request(month));
  };

  return (
    <Dropdown dropup={true} pullRight={true}>
      <Dropdown.Toggle>
        <Tooltip
          tagName="span"
          styles={{ marginLeft: '4px' }}
          tipContentClassName="tip-content--border-grey"
          arrow={false}
          background="white"
          color="black"
          direction="up"
          content={
            <div>
              <Trans>Задать дату и время</Trans>
            </div>
          }
        >
          <div style={{ display: 'inline-block' }}>
            <div>
              <span style={{ fontSize: 'large', color: '#5dbdf9' }}>{headerLocaleDate}</span>
            </div>
            <div>
              <span style={{ fontSize: 'small', color: '#5dbdf9' }}>
                {`${localeDate[2]} ${localeDate[3]} ${localeDate[4]}`}
              </span>
            </div>
          </div>
        </Tooltip>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: '270px', height: '360px' }}>
        <div className={styles.timePicker}>
          <span>{headerLocaleDate}</span>
          <div>
            <Button
              color={ButtonColor.TRANSPARENT}
              width="36px"
              height="18px"
              onClick={onIncreaseTimeClick}
            >
              <FiChevronUp size={'28px'} color={'#5dbdf9'} strokeWidth={'2px'} />
            </Button>
            <Button
              color={ButtonColor.TRANSPARENT}
              width="36px"
              height="18px"
              onClick={onDecreaseTimeClick}
            >
              <FiChevronDown size={'28px'} color={'#5dbdf9'} strokeWidth={'2px'} />
            </Button>
          </div>
        </div>
        <Dropdown.MenuDivider />
        <div>
          <DayPicker
            localeUtils={MomentLocaleUtils}
            locale={currentLocale}
            initialMonth={!isEmpty(activeItem) ? new Date(Object.keys(activeItem)[0]) : new Date()}
            modifiers={modifiers}
            disabledDays={day => !modifiers.enabledDays.includes(day)}
            showOutsideDays
            modifiersStyles={modifiersStyles}
            onDayClick={handleDayClick}
            onMonthChange={handleMonthChangeClick}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default STACItemsDatePicker;
