import { ActionType, getType } from 'deox'; // createActionCreator, createReducer
import { all, fork, put, select, takeLatest } from 'redux-saga/effects'; // call, put

import { genId } from 'components/utils/identifier';
import get from 'lodash/get';
import last from 'lodash/last';
import set from 'lodash/set';

// import GeoportalMap from 'components/map/Map/Map';
// import { selectBaseLayer } from 'ducks/mapLayers';
import { geometryFavoriteActions, getGeometryFavorites } from 'ducks/favorites';
import { getRegionsOfInterest, setShapeGeometry } from 'ducks/map';
import { GeoJSONFeature } from 'ol/format/GeoJSON';

export function* addGeometryFavoriteFlow(
  action: ActionType<typeof geometryFavoriteActions.addRequest>
) {
  try {
    const geoJSON = action.payload;
    // console.log('addGeometryFavoriteFlow', geoJSON);
    // if (!feature.getId()) {
    //   feature.setId(nanoid(6));
    // }
    // if (!get(geoJSON, 'properties._id')) {
    //   set(geoJSON, 'properties._id', nanoid(6));
    // }
    if (!geoJSON.id) {
      geoJSON.id = genId(6);
    }
    const regionsBefore: GeoJSONFeature[] = yield select(getGeometryFavorites);
    if (regionsBefore.find(r => r.id === geoJSON.id) !== undefined) {
      return;
    }
    // console.log(regionsBefore);
    set(geoJSON, 'properties._number', get(last(regionsBefore), 'properties._number', 0) + 1);
    yield put(geometryFavoriteActions.addSuccess(geoJSON));
    const regions = yield select(getGeometryFavorites); // regionsAfter
    // console.log(regions);
    localStorage.setItem('regions', JSON.stringify(regions));
    // console.log('regions', regions);
  } catch (error) {
    console.error('Error', error);
  }
}

export function* removeGeometryFavoriteFlow(
  action: ActionType<typeof geometryFavoriteActions.removeRequest>
) {
  try {
    const geoJSONId = action.payload;
    // console.log('removeGeometryFavoriteFlow', geoJSONId);
    yield put(geometryFavoriteActions.removeSuccess(geoJSONId));
    const regions = yield select(getGeometryFavorites);
    localStorage.setItem('regions', JSON.stringify(regions));
    // console.log('regions', regions);
    //
    const mapRegions = yield select(getRegionsOfInterest);
    if (get(mapRegions, '[0].id') === geoJSONId) {
      yield put(setShapeGeometry(null, false));
    }
  } catch (error) {
    console.error('Error', error);
  }
}

// TODO - объединить с загрузкой shape, map draw
export function* selectRegionOnMapFlow(
  action: ActionType<typeof geometryFavoriteActions.selectRegionOnMap>
) {
  try {
    const geoJSON = action.payload;

    yield put(setShapeGeometry(geoJSON, false));
  } catch (error) {
    console.error('Error', error);
  }
}
export function* selectRegionOnMapWatch() {
  // yield takeLatest(getType(selectRegionOnMap), selectRegionOnMapFlow);
  yield takeLatest(geometryFavoriteActions.selectRegionOnMap, selectRegionOnMapFlow);
}

export default function* mapLayersRoot() {
  yield all([takeLatest(getType(geometryFavoriteActions.addRequest), addGeometryFavoriteFlow)]);
  yield all([
    takeLatest(getType(geometryFavoriteActions.removeRequest), removeGeometryFavoriteFlow),
  ]);
  // yield all([
  //   takeLatest(getType(geometryFavoriteActions.selectRegionOnMap), selectRegionOnMapFlow),
  // ]);
  yield fork(selectRegionOnMapWatch);
}
