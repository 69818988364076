import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import { combineReducers } from 'redux';

// import { createSelector } from 'reselect';
// import get from 'lodash/get';

import { parseApiObject } from 'components/utils/apiDataConverter';
import { RootState as RootStateType } from './root';

/**
 * Types/Interfaces
 */
import { Instrument, InstrumentSensor, PlatformType } from './types/classifierTypes';

/**
 * Actions
 */
export const fetchPlatforms = {
  request: createActionCreator('CLASS/FETCH_PLATFORMS_REQUEST'),
  success: createActionCreator('CLASS/FETCH_PLATFORMS_SUCCESS', resolve => (data: any[]) => {
    /* // const byId: any = {};
    const byId: { [id: number]: PlatformType } = {};
    const allIds: number[] = [];
    for (const item of data) {
      byId[item.id] = parseApiObject(item);
      allIds.push(item.id);
    }
    return resolve({ byId, allIds }); */
    return resolve(data);
  }),
  failure: createActionCreator('CLASS/FETCH_PLATFORMS_FAILURE', resolve => error => resolve(error)),
};

/**
 * Reducers and state
 */
// { byId: {}, allIds: [] }
export const defaultPlatformTypeState: {
  byId: { [id: string]: PlatformType };
  allIds: string[];
} = {
  byId: {},
  allIds: [],
};
export const platformTypeReducer = createReducer(defaultPlatformTypeState, handleAction => [
  // handleAction(fetchPlatforms.success, (_, { payload }) => payload),
  handleAction(fetchPlatforms.success, (_, { payload }) => {
    const byId: { [id: string]: PlatformType } = {};
    const allIds: string[] = [];
    for (const item of payload) {
      byId[item.identifier] = parseApiObject(item);
      delete byId[item.identifier].platforms;
      allIds.push(item.identifier);
    }
    return { byId, allIds };
  }),
]);

// { byId: {}, allIds: [] }
export const instrumentsDefaultState: {
  byId: { [id: string]: Instrument };
  allIds: string[];
} = {
  byId: {},
  allIds: [],
};
export const instrumentReducer = createReducer(instrumentsDefaultState, handleAction => [
  // handleAction(fetchPlatforms.success, (_, { payload }) => payload),
  handleAction(fetchPlatforms.success, (_, { payload }) => {
    const byId: { [id: string]: Instrument } = {};
    const allIds: string[] = [];
    for (const plt of payload) {
      for (const sensorData of plt.sensors) {
        const sensor: InstrumentSensor = parseApiObject(sensorData);
        const instrumentIdentifier = sensor.instrumentIdentifier;

        if (allIds.indexOf(instrumentIdentifier) === -1) {
          const instrument: Instrument = {
            identifier: instrumentIdentifier,
            name: sensor.instrumentName,
            sensors: [sensor],
            platformType: plt.identifier,
          };
          allIds.push(instrumentIdentifier);
          byId[instrumentIdentifier] = instrument;
        } else {
          byId[instrumentIdentifier].sensors.push(sensor);
        }
      }
    }
    return { byId, allIds };
  }),
]);

export const platformReducer = createReducer(defaultPlatformTypeState, handleAction => [
  handleAction(fetchPlatforms.success, (_, { payload }) => {
    const byId: { [id: string]: any } = {};
    const allIds: string[] = [];
    for (const plt of payload) {
      for (const pl of plt.platforms) {
        byId[pl.identifier] = parseApiObject(pl);
        // вывод в результатах названия типа КА (можно просто оставить ссылку, а потом достать через platformTypeReducer)
        byId[pl.identifier].platformTypeId = plt.id;
        byId[pl.identifier].platformTypeName = plt.name;
        byId[pl.identifier].platformTypeIdentifier = plt.identifier;
        //
        allIds.push(pl.identifier);
      }
    }
    return { byId, allIds };
  }),
]);

// classifierRootReducer
export default combineReducers({
  // isFetching: isFetchingReducer,
  platformType: platformTypeReducer,
  platform: platformReducer,
  instrument: instrumentReducer,
});

/**
 * Selectors
 */
// export const getIsFetching = (state: RootStateType) => state.layers.isFetching;
export const getPlatformTypes = (state: RootStateType) =>
  state.class.platformType.allIds.map(ptId => state.class.platformType.byId[ptId]);
export const getPlatformTypesById = (state: RootStateType) => state.class.platformType.byId;
// export const getPlatforms = (state: RootStateType) => state.class.platforms;
// export const getPlatforms = (state: RootStateType) =>
//   state.class.platform.allIds.map(ptId => state.class.platform.byId[ptId]);
export const getPlatformsById = (state: RootStateType) => state.class.platform.byId;

export const getInstruments = (state: RootStateType) =>
  state.class.instrument.allIds.map(ptId => state.class.instrument.byId[ptId]);
export const getInstrumentsById = (state: RootStateType) => state.class.instrument.byId;
