import WebGLTileLayer from 'libs/ol/layer/WebGLTile';

import GeoTIFF from 'ol/source/GeoTIFF';
import { CreateLayerBundle } from '../types';

const createSimpleWebGLLayer = (bundle: CreateLayerBundle) => {
  const { id, name, options } = bundle;

  const source = new GeoTIFF({
    sources: [
      {
        url: options.url,
        bands: [1, 2, 3],
        // min: 0,
        // max: 700,
        // 'https://s3.gptl.ru//coverages/APOI/MSC_426EVERKVPMS4CCA544E_KV_201410-202210_3857_2-1.L3M.PMS/mosaic/MSC_426EVERKVPMS4CCA544E_KV_201410-202210_3857_2-1.L3M.PMS_1_0.tif',
        nodata: 0,
      },
    ],
    // normalize: false,
    convertToRGB: true,
  });

  const layer = new WebGLTileLayer({
    id,
    name,
    extent: options.extent,
    preload: Infinity,
    // -- /custom args delimiter --
    visible: options.visible || options.visibility || false,
    source: source,
    zIndex: options.zIndex,
  });

  return layer;
};

export default createSimpleWebGLLayer;
