import classNames from 'classnames';
import React from 'react';

import { ImageMetadata, ImageMetadataDetail } from 'ducks/types/metadataTypes';
import ResultDetail from '../ResultItem/ResultDetail';
import ResultPreview from '../ResultItem/ResultPreview';

import styles from './SearchResultItem.module.scss';
import SearchResultItemFooter from './SearchResultItemFooter';
import SearchResultItemHeader from './SearchResultItemHeader';

interface SearchResultItemProps {
  data: ImageMetadataDetail;
  // flags
  isActive: boolean;
  isChecked: boolean;
  isOpen?: boolean;
  isInCart: boolean;
  isContourActive: boolean;
  isPreviewActive: boolean;

  actions: {
    // header
    onActivate: (md: ImageMetadataDetail) => void;
    onToggleCheck: (md: ImageMetadataDetail) => void;
    onAddToCart: (md: ImageMetadataDetail) => void;
    onRemove: (md: ImageMetadataDetail) => void;
    onCopyToClipboard: (text: string) => void;
    // body
    onToggleOpen: (md: ImageMetadata, flag?: boolean) => void;
    // footer
    onToggleContour: (md: ImageMetadataDetail) => void;
    onTogglePreview: (md: ImageMetadataDetail) => void;
    onToggleFullImage: (ids: string[]) => void;
    onMetadataDownload: (md: ImageMetadataDetail) => void;
  };
}

const SearchResultItem: React.FC<SearchResultItemProps> = props => {
  const { data, isActive, isChecked, isOpen, isInCart } = props;

  return (
    <div
      className={classNames(styles.listItem, {
        [styles.listItemSelected]: isActive,
      })}
    >
      <div className={styles.headContainer}>
        <SearchResultItemHeader
          data={data}
          isActive={isActive}
          isChecked={isChecked}
          isInCart={isInCart}
          onActivate={props.actions.onActivate}
          onToggleCheck={props.actions.onToggleCheck}
          onCopyToClipboard={props.actions.onCopyToClipboard}
          onAddToCart={props.actions.onAddToCart}
          onRemove={props.actions.onRemove}
        />
      </div>

      <div className={styles.bodyContainer}>
        {isOpen ? (
          <ResultDetail
            metadata={data}
            actions={{ handleAccordionToggle: props.actions.onToggleOpen }}
          />
        ) : (
          <ResultPreview
            metadata={data}
            actions={{ handleAccordionToggle: props.actions.onToggleOpen }}
          />
        )}
      </div>

      <div>
        <SearchResultItemFooter
          data={data}
          isContourActive={props.isContourActive}
          isPreviewActive={props.isPreviewActive}
          onToggleContour={props.actions.onToggleContour}
          onTogglePreview={props.actions.onTogglePreview}
          onToggleFullImage={props.actions.onToggleFullImage}
          onMetadataDownload={props.actions.onMetadataDownload}
        />
      </div>
    </div>
  );
};

export default SearchResultItem;
