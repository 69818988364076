// import { css } from 'astroturf';
import { Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import Button from '@geobank/components/src/common/Button/Button';
import IconButton from '@geobank/components/src/common/IconButton/IconButton';

import { formatDate, parseDate } from 'components/utils/date';
import { formatValue } from 'components/utils/format';
import { fetchImageMetadata } from 'ducks/metadata';
import { ImageMetadata } from 'ducks/types/metadataTypes';
import { instruments } from 'locales/dicts/classifiers';
import { renderResolution } from 'pages/HomePage/sections/SearchResult/ResultItem/helpers';

import styles from '../MapBalloon.module.scss';

interface ImageMetadataBalloonProps {
  data: any;
  onClose: () => void;
}

const ImageMetadataBalloon: React.FC<ImageMetadataBalloonProps> = props => {
  const { data, onClose } = props;
  const [imageMetadata, setImageMetadata] = useState<ImageMetadata | undefined>();
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const handleAddToCart = () => {
    // console.log('handleAddToCart', imageMetadata);
    // ...dispatch on elem!
    const event = new CustomEvent('show-add-image-form', { bubbles: true, detail: imageMetadata }); // (2)
    document.dispatchEvent(event);
    onClose();
  };

  useEffect(() => {
    if (!imageMetadata) {
      // call ajax request
      const ids = String(data.metadata_id);
      dispatch(
        fetchImageMetadata.request({
          ids,
          processingLevelCode: ['L0'],
          limit: 1,
          offset: 0,
          callback: resp => {
            if (resp.results) {
              setImageMetadata(resp.results[0]);
            }
          },
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div className={styles.header}>
          <h3>
            <Trans>Снимок</Trans>
          </h3>
          <IconButton onClick={handleClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div>
          <div className={styles.dataListItem}>
            <div className={styles.dataTitle}>
              <Trans>Номер витка / Включение</Trans>
            </div>
            <div className={styles.dataValue}>
              {data.circuit_number} / {data.scan_number}
            </div>
          </div>
          <div className={styles.dataListItem}>
            <div className={styles.dataTitle}>
              <Trans>Дата съёмки</Trans>
            </div>
            <div className={styles.dataValue}>{formatDate(parseDate(data.date_instant))}</div>
          </div>
          <div className={styles.dataListItem}>
            <div className={styles.dataTitle}>
              {/* Аппарат */}
              <Trans>Космический аппарат</Trans>
            </div>
            <div className={styles.dataValue}>{data.platform}</div>
          </div>
          {imageMetadata && (
            <React.Fragment>
              <div className={styles.dataListItem}>
                <div className={styles.dataTitle}>
                  <Trans>Съёмочное устройство</Trans>
                </div>
                <div className={styles.dataValue}>
                  <Trans id={instruments[imageMetadata.instrumentIdentifier]} />
                </div>
              </div>
              <div className={styles.dataListItem}>
                <div className={styles.dataTitle}>
                  <Trans>Облачность</Trans>
                </div>
                <div className={styles.dataValue}>{formatValue(imageMetadata.cloudiness, '%')}</div>
              </div>
              <div className={styles.dataListItem}>
                <div className={styles.dataTitle}>
                  <Trans>Разрешение_</Trans>
                </div>
                <div className={styles.dataValue}>{renderResolution(imageMetadata.resolution)}</div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        {imageMetadata && (
          <Button width="100%" onClick={handleAddToCart}>
            <Trans>Добавить в корзину</Trans>
          </Button>
        )}
      </div>
    </div>
  );
};

export default ImageMetadataBalloon;
