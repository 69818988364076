
import { getLanguage } from 'ducks/locale';
import React from 'react';
import { useSelector } from 'react-redux';

const styles = require('./YandexLogo-styles.module.scss');

const YandexLogo = () => {
  const locale = useSelector(getLanguage);
  return (
    <div className={styles.root}>
      <a href=" https://yandex.ru/maps" target="_blank">
        <img className={styles.image} src={`images/yandex/yndex_logo_${locale}.png`} alt="" />
      </a>
    </div>
  );
};

export default YandexLogo;
