import { ActionType, getType } from 'deox'; // createActionCreator, createReducer
import { all, call, put, select, takeLatest } from 'redux-saga/effects'; // , put

import GeoportalMap, { LAYER_GROUPS, mosaicLayerIds } from 'components/map/Map/Map';
import {
  changeTopLayerUrl,
  getBaseLayers,
  getMosaicLayers,
  getTopLayers,
  toggleBaseLayer,
  toggleMosaicLayer,
  toggleProductPreviewLayer,
  toggleTopLayer,
} from 'ducks/mapLayers';

import { clearShapeGeometry, setShapeGeometry } from 'ducks/map';

import { fetchDates } from 'ducks/stacItems';
import { Debugger } from 'utils/logging';

const debug = Debugger('MapSaga'); // TAG

export const getNestedObjectValue: any = (obj: any, target: any) =>
  target in obj
    ? obj[target]
    : // @ts-ignore
      Object.values(obj).reduce((acc, val) => {
        if (typeof val === 'object') return getNestedObjectValue(val, target);
        return acc;
      }, {});

// const getNestedObjectValue: any = (node: any) => {
//   if (node.leftChild) {
//     return getNestedObjectValue(node.leftChild);
//   } else if (node.rightChild) {
//     return getNestedObjectValue(node.rightChild);
//   } else { // node must be a leaf node
//     return node;
//   }
// }

export function* toggleBaseLayerFlow(action: ActionType<typeof toggleBaseLayer>) {
  try {
    const layerId: string = action.payload;
    const map = GeoportalMap.getInstance();
    yield call([map, 'toggleBaseLayer'], layerId);
    if (layerId !== 'Yandex') {
      yield call([map, 'removeLayerById'], 'YandexHybrid', LAYER_GROUPS.topLayers);
    }
  } catch (error) {
    debug.error(error);
  }
}

type MosaicLayers = ReturnType<typeof getMosaicLayers>;
export function* toggleMosaicLayerFlow(action: ActionType<typeof toggleMosaicLayer>) {
  try {
    const layerId: string = action.payload;
    const map = GeoportalMap.getInstance();
    yield call([map, 'removeMosaicLayers']);
    yield call([map, 'removeBrightnessTemperatureControl']);
    if (mosaicLayerIds.includes(layerId)) {
      yield put(fetchDates.request(new Date()));
    } else if (layerId === 'BBP_Geoton') {
      const mosaicLayers: MosaicLayers = yield select(getMosaicLayers);
      yield call(
        [map, 'toggleMosaicLayer'],
        mosaicLayers.find(layer => layer.id === layerId)
      );
    }
  } catch (error) {
    debug.error(error);
  }
}

type TopLayers = ReturnType<typeof getTopLayers>;

export function* toggleTopLayerFlow(action: ActionType<typeof toggleTopLayer>) {
  try {
    const layerId: string = action.payload;
    const topLayers: TopLayers = yield select(getTopLayers);
    const map = GeoportalMap.getInstance();
    yield call(
      [map, 'toggleTopLayer'],
      topLayers.find(layer => layer.id === layerId)
    );
  } catch (error) {
    debug.error(error);
  }
}

export function* changeTopLayerUrlFlow(action: ActionType<typeof changeTopLayerUrl>) {
  const language = action.payload;
  try {
    const baseLayers: ReturnType<typeof getBaseLayers> = yield select(getBaseLayers);
    const topLayers: ReturnType<typeof getTopLayers> = yield select(getTopLayers);
    const map = GeoportalMap.getInstance();
    yield call(
      [map, 'updateYandexLayerUrl'],
      topLayers.find(layer => layer.id === 'YandexHybrid'),
      language,
      LAYER_GROUPS.topLayers
    );
    yield call(
      [map, 'updateYandexLayerUrl'],
      baseLayers.find(layer => layer.id === 'Yandex'),
      language,
      LAYER_GROUPS.baseLayers
    );
  } catch (error) {
    console.error('Error Change layer', error);
  }
}

export function* setRegionGeometryFlow(action: ActionType<typeof setShapeGeometry>) {
  try {
    const {
      payload,
      meta: { isZoomNeed },
    } = action;
    const map = GeoportalMap.getInstance();
    yield call([map, 'addRegionOfInterestLayer'], payload, isZoomNeed);
  } catch (error) {
    debug.error(error);
  }
}

export function* clearRegionGeometryFlow() {
  try {
    const map = GeoportalMap.getInstance();
    yield call([map, 'clearRegionsOfInterest']);
  } catch (error) {
    debug.error(error);
  }
}

export function* toggleProductPreviewLayerFlow(
  action: ActionType<typeof toggleProductPreviewLayer>
) {
  try {
    const { identifier, url, bbox } = action.payload;
    // const imageLayers = yield select(getImageLayersById);
    debug.log('toggleProductPreviewLayerFlow', identifier /* imageLayers */);

    const map = GeoportalMap.getInstance();
    yield call([map, 'toggleProductPreviewLayer'], identifier, url, bbox); // imageLayers[layerId]
  } catch (error) {
    debug.error(error);
  }
}

export default function* mapLayersRoot() {
  yield all([takeLatest(getType(toggleBaseLayer), toggleBaseLayerFlow)]);
  yield all([takeLatest(getType(toggleMosaicLayer), toggleMosaicLayerFlow)]);
  yield all([takeLatest(getType(toggleTopLayer), toggleTopLayerFlow)]);
  yield all([takeLatest(getType(setShapeGeometry), setRegionGeometryFlow)]);
  yield all([takeLatest(getType(clearShapeGeometry), clearRegionGeometryFlow)]);
  yield all([takeLatest(getType(changeTopLayerUrl), changeTopLayerUrlFlow)]);
  yield all([takeLatest(getType(toggleProductPreviewLayer), toggleProductPreviewLayerFlow)]);
}
