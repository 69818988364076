import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import { combineReducers } from 'redux';

import * as stacItemsTypes from './types/stacItemsTypes';

import { RootState as RootStateType } from './root';

/**
 * Actions
 */
export const fetchItems = {
  request: createActionCreator('STAC_ITEMS/FETCH_ITEMS_REQUEST', resolve => (date?: string) =>
    resolve(date)
  ),
  success: createActionCreator(
    'STAC_ITEMS/FETCH_ITEMS_SUCCESS',
    resolve => (data: stacItemsTypes.STACItem[]) => resolve(data)
  ),
  failure: createActionCreator('STAC_ITEMS/FETCH_ITEMS_FAILURE', resolve => error =>
    resolve(error)
  ),
};

export const clearItems = createActionCreator(
  'STAC_ITEMS/ITEMS_CLEAR',
  resolve => (layerId: string) => resolve(layerId)
);

// export const clearItems = createActionCreator('STAC_ITEMS/ITEMS_CLEAR');

export const fetchDates = {
  request: createActionCreator('STAC_ITEMS/FETCH_DATES_REQUEST', resolve => (date?: Date) =>
    resolve(date)
  ),
  success: createActionCreator('STAC_ITEMS/FETCH_DATES_SUCCESS', resolve => (data: string[]) =>
    resolve(data)
  ),
  failure: createActionCreator('STAC_ITEMS/FETCH_DATES_FAILURE', resolve => error =>
    resolve(error)
  ),
};

export const changeActiveItemIndex = createActionCreator(
  'STAC_ITEMS/CHANGE_ACTIVE_ITEM',
  resolve => (index: number) => resolve(index)
);

export const changeStartTime = {
  request: createActionCreator('STAC_ITEMS/CHANGE_START_TIME_REQUEST'),
  success: createActionCreator('STAC_ITEMS/CHANGE_START_TIME_SUCSESS', resolve => (data: string) =>
    resolve(data)
  ),
};

export const animateItems = createActionCreator(
  'STAC_ITEMS/ANIMATE_ITEMS',
  resolve => (data: stacItemsTypes.AnimateState) => resolve(data)
);
/**
 * Reducers and state
 */
export const defaultAnimateState: stacItemsTypes.AnimateState = {
  isAnimate: false,
  direction: 'forward',
  delay: 100,
  step: 1,
  animateImages: [],
};

export const animateItemsReducer = createReducer(defaultAnimateState, handleAction => [
  handleAction(animateItems, (state, { payload }) => ({ ...state, ...payload })),
]);

export const fetchItemsDefaultState: stacItemsTypes.STACItem[] = [];
export const fetchItemsReducer = createReducer(fetchItemsDefaultState, handleAction => [
  handleAction(fetchItems.success, (_, { payload }) => payload),
  handleAction(clearItems, () => []),
]);

export const fetchDatesDefaultState: string[] = [];
export const fetchDatesReducer = createReducer(fetchDatesDefaultState, handleAction => [
  handleAction(fetchDates.success, (_, { payload }) => payload),
]);

export const isFetchingItemsDefaultState = false;

export const isFetchingItemsReducer = createReducer(isFetchingItemsDefaultState, handleAction => [
  handleAction(fetchItems.request, () => true),
  handleAction([fetchItems.success, fetchItems.failure], () => false),
]);

export const activeItemIndexDefaultState: number = 0;
// {
//   layerId: undefined,
//   index: undefined,
// };

export const changeActiveItemIndexReducer = createReducer(
  activeItemIndexDefaultState,
  handleAction => [handleAction(changeActiveItemIndex, (_, { payload }) => payload)]
);

export const startTimeDefaultState: string = '';
export const changeStartTimeReducer = createReducer(startTimeDefaultState, handleAction => [
  handleAction(changeStartTime.success, (_, { payload }) => payload),
]);

// layersRootReducer
export default combineReducers({
  animateItems: animateItemsReducer,
  items: fetchItemsReducer,
  itemsDates: fetchDatesReducer,
  isFetchingItems: isFetchingItemsReducer,
  activeItemIndex: changeActiveItemIndexReducer,
  startTime: changeStartTimeReducer,
});

/**
 * Selectors
 */
export const getAnimateItems = (state: RootStateType) => state.stacItems.animateItems;
export const getItems = (state: RootStateType) => state.stacItems.items;
export const getItemsDates = (state: RootStateType) => state.stacItems.itemsDates;
export const getIsFetchingItems = (state: RootStateType) => state.stacItems.isFetchingItems;
export const getActiveItemIndex = (state: RootStateType) => state.stacItems.activeItemIndex;
export const getStartTime = (state: RootStateType) => state.stacItems.startTime;
