import { camelToSnake, snakeToCamel } from '@geobank/assets/src/uibook/helpers/utils';
import { QUICKLOOK_TRANSFORM_URL, SEARCH_IMAGES_API_URL } from 'api/realAPI';
import { geoJSONToWKTAndTransform, wktToGeoJSON } from 'components/map/Map/utils';
import { captureSimpleMessage } from 'modules/monitor/sentryHelper';
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import { formatDate, parseDate } from './date';
import { formatNumber } from './format';

interface IBand {
  name: string;
  common_name: string;
  center_wavelength: number;
  full_width_half_max: number;
  description: string;
}

interface ILink {
  href: string;
  rel: string;
}

interface IBandRange {
  id: string;
  description: string;
  min: string;
  max: string;
}

interface IUrl {
  protocolType: string;
  hostName: string;
  pathName: string;
}

interface IBaseSensor {
  number: number;
  id: number;
  instrumentIdentifier: string;
  bands: IBandRange[];
  isPanchromatic: boolean;
}

const getBandRange = (
  id: string,
  description: string,
  centerWaveLength: string,
  fullFidthHalfMax: string
): IBandRange => {
  const deltaWidth: string = (Number(fullFidthHalfMax) / 2).toFixed(3);
  return {
    id: id,
    description: description,
    min: Number(Number(centerWaveLength) - Number(deltaWidth)).toFixed(2),
    max: Number(Number(centerWaveLength) + Number(deltaWidth)).toFixed(2),
  };
};

export const parseUrl = (url: string): IUrl => {
  const splittedUrl = url.split('//');
  const urlProtocol = splittedUrl[0];
  const urlDomain = splittedUrl[1].split('/')[0];
  const urlPath = splittedUrl[1].split('/').slice(1).join('/');
  return {
    protocolType: urlProtocol,
    hostName: urlDomain,
    pathName: urlPath,
  };
};

/**
 * Parser
 */
const parseObject = (data: any) => {
  if (data === null || data === undefined) {
    return null;
  }
  let result: any;
  // TODO - isObject()
  if (Array.isArray(data)) {
    result = data.map(item => parseObject(item));
  } else if (typeof data === 'object') {
    result = {};
    Object.keys(data).forEach(key => {
      result[snakeToCamel(key)] = parseObject(data[key]);
    });
  } else {
    result = data;
  }
  return result;
};

const parseCoverageObject = (data: any) => {
  if (!Array.isArray(data)) {
    return null;
  }
  const result = data.map(item => {
    const element = parseObject(item);
    // throw Error
    if (!element.resourceUrls) {
      captureSimpleMessage("User can't see full resolution image", undefined, {
        element_id: element.id,
        element_name: element.name,
        format_name: element.formatName,
        service_name: element.serviceName,
        metadata_identifier: element.metadataIdentifier,
      });
    }
    return element;
  });
  return result;
};

// parseMetadataResult
export const parseMetadataObject = (data: any) => {
  const result: any = {};
  Object.keys(data).forEach(key => {
    switch (key) {
      case 'acquisition_date_instant':
      case 'acquisition_date_begin':
      case 'acquisition_date_end':
      case 'last_modified':
        result[snakeToCamel(key)] = data[key] ? parseDate(data[key]) : null; // parseISODate(data[key]) // , format, new Date()
        break;
      // своего рода костыль, из-за особенности использования ES
      // округление чисел с большим количеством знаков после запятой
      case 'cloudiness':
      case 'resolution':
      case 'illumination_azimuth_angle':
      case 'illumination_elevation_angle':
      case 'nadir_tilt_angle':
      case 'azimuth_scan_angle':
        result[snakeToCamel(key)] =
          Number(data[key]) === data[key] ? Math.round(data[key] * 10) / 10 : null;
        break;
      // arrays
      case 'previews':
        result[snakeToCamel(key)] = Array.isArray(data[key])
          ? data[key].map((item: any) => {
              item[`url`] = item.url.replace('http://www.gptl.ru', 'https://www.gptl.ru');
              // item[`url`] =
              //   data.identifier.startsWith('ETRIS.KV') ||
              //   data.identifier.startsWith('ETRIS.RDK') ||
              //   data.identifier.startsWith('ETRIS.BKA') ||
              //   data.identifier.startsWith('ETRIS.AI2D')
              //     ? /* https://api.gptl.ru/transform/v1/quickloook */
              //       `${QUICKLOOK_TRANSFORM_URL}?url=${item.url}&geometry=${item.geometry}`
              // : item[`url`];
              item[`url`] = `${QUICKLOOK_TRANSFORM_URL}?url=${item.url}&geometry=${item.geometry}`;
              return parseObject(item);
            })
          : [];
        break;
      case 'sensors':
      case 'resp_party_data_storage': // case 'coverage':
        result[snakeToCamel(key)] = data[key] !== null ? parseObject(data[key]) : [];
        break;
      case 'coverage':
        result[snakeToCamel(key)] = data[key] !== null ? parseCoverageObject(data[key]) : [];
        break;
      default:
        result[snakeToCamel(key)] = data[key];
    }
  });
  return result;
};
export const parseBbpMetadataObject = (data: any) => {
  const result: any = {};
  Object.keys(data).forEach(key => {
    switch (key) {
      case 'acquisition_date':
      case 'acquisition_date_begin':
      case 'acquisition_date_end':
      case 'last_modified':
        result[snakeToCamel(key)] = data[key] ? parseDate(data[key]) : null; // parseISODate(data[key]) // , format, new Date()
        break;
      case 'illumination_azimuth_angle':
      case 'illumination_elevation_angle':
      case 'nadir_tilt_angle':
      case 'azimuth_scan_angle':
        result[snakeToCamel(key)] =
          typeof data[key] === 'number' ? Math.round(data[key] * 10) / 10 : null;
        break;
      // arrays
      case 'previews':
        result[snakeToCamel(key)] = Array.isArray(data[key])
          ? data[key].map((item: any) => {
              item.url = `${SEARCH_IMAGES_API_URL}/bbp/previews?id=${data.id}`;
              return parseObject(item);
            })
          : [];
        break;
      case 'sensors':
      case 'resp_party_data_storage':
      case 'coverage':
        // TODO - parse dates!?
        result[snakeToCamel(key)] = data[key] !== null ? parseObject(data[key]) : [];
        break;
      default:
        result[snakeToCamel(key)] = data[key];
    }
  });
  if (!result.coverage) {
    result.coverage = [];
  }
  return result;
};

export const parseStacMetadataObject = (data: any) => {
  const result: any = {};
  const geometry = new WKT().writeFeature(new GeoJSON().readFeature(data.geometry));
  const instrument = Array(data.properties.instruments).join();

  Object.keys(data).forEach(key => {
    switch (key) {
      case 'stac_version':
      case 'type':
      case 'stac_extensions':
      case 'collection':
        break;
      case 'id':
        result.identifier = data[key];
        break;
      case 'geometry':
        result[snakeToCamel(key)] = geometry;
        break;
      case 'bbox':
        result.bbox = data[key];
        break;
      case 'properties':
        const properties = data.properties;
        Object.keys(properties).forEach(prop => {
          switch (prop) {
            case 'title':
            case 'description':
              break;
            case 'datetime':
              result.acquisitionDateInstant = new Date(properties[prop]);
              break;
            case 'platform':
              result.platformIdentifier = properties[prop];
              properties[prop] === 'KVIK'
                ? (result.platformTypeIdentifier = properties[prop]?.slice(0, -2))
                : (result.platformTypeIdentifier = properties[prop]?.slice(0, -1));
              break;
            case 'instruments':
              instrument === 'MSUTM101,MSUTM102'
                ? (result.instrumentIdentifier = 'KMSS')
                : (result.instrumentIdentifier = instrument);
              break;
            case 'eo:cloud_cover':
              result.cloudiness = Number(properties[prop]).toFixed(2);
              break;
            case 'processing:level':
              result.processingLevelCode = properties[prop];
              break;
            case 'eo:bands':
              const sensors: IBaseSensor[] = [];
              const multispectrBands = properties[prop]
                .filter((sensor: any) => sensor.common_name !== 'pan')
                .map((band: any) => {
                  const bandRange: IBandRange = getBandRange(
                    band.id,
                    band.name,
                    band.center_wavelength,
                    band.full_width_half_max
                  );
                  return bandRange;
                });
              const panchromeBands = properties[prop]
                .filter((sensor: any) => sensor.common_name === 'pan')
                .map((band: any) => {
                  const bandRange: IBandRange = getBandRange(
                    band.id,
                    band.name,
                    band.center_wavelength,
                    band.full_width_half_max
                  );
                  return bandRange;
                });
              if (multispectrBands.length > 0) {
                const multispectrSensor: IBaseSensor = {
                  number: 1,
                  id: 3,
                  instrumentIdentifier: properties.instruments.join(),
                  bands: multispectrBands,
                  isPanchromatic: false,
                };
                sensors.push(multispectrSensor);
              }
              if (panchromeBands.length > 0) {
                const panchromeSensor: IBaseSensor = {
                  number: 1,
                  id: 3,
                  instrumentIdentifier: properties.instruments.join(),
                  bands: panchromeBands,
                  isPanchromatic: true,
                };
                sensors.push(panchromeSensor);
              }
              result.sensors = sensors;
              break;
            case 'sat:orbit_state':
              result.satOrbitState = properties[prop];
              break;
            case 'sat:absolute_orbit':
              result.satAbsoluteOrbit = formatNumber(Number(properties[prop]));
              break;
            case 'view:off_nadir':
              result.nadirTiltAngle = Number(properties[prop]).toFixed(2);
              break;
            case 'view:azimuth':
              result.azimuthScanAngle = Number(properties[prop]).toFixed(2);
              break;
            case 'view:sun_azimuth':
              result.illuminationAzimuthAngle = Number(properties[prop]).toFixed(2);
              break;
            case 'view:sun_elevation':
              result.illuminationElevationAngle = Number(properties[prop]).toFixed(2);
              break;
            case 'providers':
              result.respPartyDataStorage = properties[prop].map(
                (prov: { name: string; url: string }) => ({
                  organizationName: prov.name,
                  url: prov.url,
                })
              );
              break;
            default:
          }
        });
        break;
      case 'assets':
        instrument === 'MSS,PSS'
          ? (result.instrumentIdentifier = 'MSS')
          : (result.instrumentIdentifier = instrument);
        const previewUrls: any[] = Object.keys(data[key]).filter(
          asset => asset === 'thumbnail_2' || asset.startsWith('thumbnail.png.ms')
        );
        result.previews = previewUrls.map(previewUrl => ({
          // geometry: geoJSONToWKTAndTransform(previewUrl['proj:geometry']),
          geometry: data[key][previewUrl].hasOwnProperty('proj:geometry')
            ? geoJSONToWKTAndTransform(
                data[key][previewUrl]['proj:geometry'],
                data[key][previewUrl]['proj:epsg'] === 3857
              )
            : geometry,
          url: `${process.env.REACT_APP_QUICKLOOK_TRANSFORM_URL}?url=${
            data[key][previewUrl].href
          }&geometry=${
            data[key][previewUrl].hasOwnProperty('proj:geometry')
              ? geoJSONToWKTAndTransform(
                  data[key][previewUrl]['proj:geometry'],
                  data[key][previewUrl]['proj:epsg'] === 3857
                )
              : geometry
          }`,
        }));
        break;
      case 'links':
        const fullResolutionLinks: any[] = data[key].filter((link: any) => link.rel === 'xyz');
        if (fullResolutionLinks.length > 0) {
          const coverages = fullResolutionLinks.map(link => {
            return {
              // ! Добавить расчет bounds (возможно из геометрии)
              bounds: '',
              bandCombination: 'ms',
              geometry: geometry,
              resourceUrls: { tms: link.href },
            };
          });
          result.coverage = coverages;
        } else {
          result.coverage = [];
        }
        break;
      default:
        result[snakeToCamel(key.replace(':', '_'))] = data[key];
    }
  });
  return result;
};

// v2 of parseApiObject, parseApiDataObjectV2, api response data
export const parseApiObjectV2 = (
  data: any,
  types: { [key: string]: string } = { createdAt: 'date' }, // types: { [key: string]: string } | string
  currentType?: string
) => {
  let result: any;

  if (Array.isArray(data)) {
    result = data.map((item: any) => parseApiObjectV2(item, types));
  } else if (typeof data === 'object' && data !== null) {
    result = {};
    Object.keys(data).forEach(key => {
      const newKey = snakeToCamel(key);
      result[newKey] = parseApiObjectV2(
        data[key],
        types, // typeof types === 'object' && types[newKey] ? types[newKey] : undefined
        types[newKey] ? types[newKey] : undefined
      );
    });
  } else {
    // switch (types[newKey]) {
    switch (currentType) {
      case 'date':
        // result[newKey] = data[key] ? parseDate(data[key]) : null;
        result = data ? parseDate(data) : null;
        break;
      default:
        result = data;
    }
  }

  return result;
};

export const parseApiObject = (data: any) => {
  const result: any = {};
  Object.keys(data).forEach(key => {
    result[snakeToCamel(key)] = parseObject(data[key]);
  });
  return result;
};

/**
 * Serializer
 */
const serializeValue = (value: any) => {
  if (value instanceof Date) {
    return formatDate(value, "yyy-MM-dd'T'HH:mm:ss");
  } else if (Array.isArray(value)) {
    return value.join(',');
  }
  return value;
};

export const serializeMetadataStacSearchParams = (params: any) => {
  const paramsForApi: any = {};
  Object.keys(params).forEach(key => {
    switch (key) {
      case 'acquisitionDateAfter':
      case 'acquisitionDateBefore':
      case 'datetime':
        const dateBegin = params.acquisitionDateAfter
          ? new Date(params.acquisitionDateAfter).toISOString()
          : '..';
        paramsForApi.datetime = `${dateBegin}/${new Date(
          params.acquisitionDateBefore
        ).toISOString()}`;
        break;
      case 'datetimes':
        paramsForApi.datetimes = params[key];
        paramsForApi.datetime = undefined;
        break;
      case 'ids':
        paramsForApi.ids = params.geometry ? undefined : [params[key]];
        break;
      case 'geometry':
        params[key].startsWith('POLYGON')
          ? (paramsForApi.intersects = wktToGeoJSON(params[key]))
          : (paramsForApi.intersects = JSON.parse(params[key]));
        break;
      case 'cloudinessMax':
        paramsForApi.eo_cloud_cover =
          params.imageType === 'optic' ? `../${params[key]}` : undefined;
        break;
      case 'processingLevelCode':
        params.imageType === 'optic'
          ? (paramsForApi.processing_level_codes = ['L0', 'L2', 'L2A'])
          : (paramsForApi.processing_level_codes = params[key]); // ['L2B1', 'L2A1']
        break;
      case 'instrumentIdentifiers':
        if (params.imageType === 'optic') {
          params[key].length > 0
            ? (paramsForApi.instruments = params[key])
            : (paramsForApi.instruments = undefined);
        }
        break;
      case 'illuminationElevationAngleMin':
        paramsForApi.view_sun_elevation = params[key];
        break;
      case 'illuminationElevationAngleMax':
        break;
      case 'nadirTiltAngleMin':
        paramsForApi.view_off_nadir = params[key];
        break;
      case 'sarInstrumentModes':
        paramsForApi.sar_instrument_modes = params.imageType === 'radar' ? params[key] : undefined;
        break;
      case 'sarPolarizations':
      case 'sarObservationDirection':
      case 'satOrbitState':
        params[key] && params[key].length > 0
          ? (paramsForApi[camelToSnake(key)] = params[key])
          : (paramsForApi[camelToSnake(key)] = undefined);
        break;
      case 'sortBy':
        paramsForApi.sortby = params[key];
        break;
      case 'nadirTiltAngleMax':
        break;
      case 'sourceType':
      case 'imageType':
      case 'modal':
      case 'regionSelection':
      case 'archiveObjectType':
      case 'invalidParams':
        break;
      default:
        if (params[key] !== null || params[key] !== undefined || params[key] !== '') {
          paramsForApi[camelToSnake(key)] = serializeValue(params[key]);
        }
    }
  });
  return paramsForApi;
};

export const serializeMetadataSearchParams = (params: any) => {
  const paramsForApi: any = {};
  Object.keys(params).forEach(key => {
    switch (key) {
      case 'sourceType':
      case 'imageType':
      case 'modal':
      case 'regionSelection':
      case 'invalidParams':
      case 'archiveObjectType':
      case 'sarInstrumentModes':
      case 'datetime':
        break;
      case 'ids':
        paramsForApi.identifier = params.geometry ? undefined : params[key];
        break;
      case 'instrumentIdentifiers':
        if (params[key].includes('MUXNAD')) {
          const index = params[key].indexOf('MUXNAD');
          if (params[key].includes('MUX')) {
            params[key].splice(index, 1);
          } else {
            params[key][index] = 'MUX';
          }
        }
        paramsForApi.instrument_identifiers = params[key].join(',');
        break;
      case 'platformTypeIdentifier':
        if (params[key].includes('MMBBP')) {
          const index = params[key].indexOf('MMBBP');
          params[key][index] = 'MM';
        }
        paramsForApi.platform_type_identifier = params[key].join(',');
        break;
      default:
        if (params[key] !== null || params[key] !== undefined || params[key] !== '') {
          paramsForApi[camelToSnake(key)] = serializeValue(params[key]);
        }
    }
  });
  return paramsForApi;
};

export const serializeTaskParams = (params: any) => {
  const paramsForApi: any = {};
  Object.keys(params).forEach(key => {
    if (params[key] !== null || params[key] !== undefined || params[key] !== '') {
      paramsForApi[camelToSnake(key)] = params[key];
    }
  });
  return paramsForApi;
};

export const serializeParams = (params: any) => {
  const paramsForApi: any = {};
  Object.keys(params).forEach(key => {
    if (params[key] || typeof params[key] === 'boolean') {
      // TODO - сделать по красивому (фикс для диапазонов bands)
      paramsForApi[camelToSnake(key)] = key === 'bands' ? params[key] : serializeValue(params[key]);
    }
  });
  return paramsForApi;
};

/// Сериализация объектов перед отправкой в api (без конкатенации массивов в строки через ,)
export const serializeDataV2 = (data: any): any => {
  let result;
  if (data === null) {
    result = data;
  } else if (Array.isArray(data)) {
    result = [];
    result = data.map(item => serializeDataV2(item));
  } else if (typeof data === 'object') {
    result = {};
    Object.keys(data).forEach(key => {
      if (data[key] !== undefined) {
        result[camelToSnake(key)] = serializeDataV2(data[key]);
      }
    });
  } else if (typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean') {
    result = data;
  }

  return result;
};
