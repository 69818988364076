import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import { combineReducers } from 'redux';

// import { createSelector } from 'reselect';
// import get from 'lodash/get';
import { GeoJSONFeature } from 'ol/format/GeoJSON';

import { RootState as RootStateType } from './root';
// export type RootState = RootStateType;
// import { searchImages as searchMetadata } from './metadata';

/**
 * Types/Interfaces
 */
// ---

/**
 * Actions
 */
export const drawControl = {
  // SHOW_DRAW_CONTROL, HIDE_DRAW_CONTROL
  show: createActionCreator('MAP/SHOW_DRAW_CONTROL'),
  hide: createActionCreator('MAP/HIDE_DRAW_CONTROL'),
  // SET_DRAW_GEOMETRY
  setGeometry: createActionCreator('MAP/SET_DRAW_GEOMETRY', resolve => (data?: GeoJSONFeature) =>
    // (data?: string) =>
    resolve(data)
  ),
};

export const setGeofenceGeometry = createActionCreator(
  'MAP/SET_GEOFENCE_GEOMETRY',
  resolve => (data: GeoJSONFeature | null) => resolve(data)
);

// save state and add vector layer
export const setShapeGeometry = createActionCreator(
  'MAP/SET_SHAPE_GEOMETRY',
  // resolve => (data: string) => resolve(data),
  resolve => (data: GeoJSONFeature | null, isZoomNeed: boolean) => resolve(data, { isZoomNeed })
);

export const clearShapeGeometry = createActionCreator('MAP/CLEAR_GEOMETRY');

// TODO - save logs to use back button
export const balloonToggle = createActionCreator(
  'MAP/BALLOON_TOGGLE',
  resolve => (check?: boolean, data?: any) => resolve(check, { data: data || {} })
);

export const mainPanelToggle = createActionCreator(
  'MAP/MAIN_PANEL_TOGGLE',
  resolve => (data?: boolean) => resolve(data)
);

/**
 * Reducers and state
 */
export const defaultIsDrawControlShowState = false;

export const isDrawControlShowReducer = createReducer(
  defaultIsDrawControlShowState,
  handleAction => [
    handleAction(drawControl.show, () => true),
    handleAction(drawControl.hide, () => false),
    // handleAction(searchMetadata.request, () => false),
  ]
);

// export const defaultDrawGeometryState: string = ''; // how set null/undefined ?

// export const drawGeometryReducer = createReducer(defaultDrawGeometryState, handleAction => [
//   handleAction(drawControl.setGeometry, (_, { payload }) => payload),
// ]);

// TODO - remove drawGeometryReducer
// export const defaultRegionGeometryState: string = '';
// geojson | null
export const defaultRegionsOfInterestState: GeoJSONFeature[] = []; // object[]

export const regionsOfInterestReducer = createReducer(
  defaultRegionsOfInterestState,
  handleAction => [
    handleAction(drawControl.setGeometry, (_, { payload }) => [payload]),
    handleAction(setShapeGeometry, (_, { payload }) => (payload !== null ? [payload] : [])),
    handleAction(drawControl.hide, () => defaultRegionsOfInterestState),
    handleAction(clearShapeGeometry, () => defaultRegionsOfInterestState),
  ]
);

export const defaultGeofenceGeometryState: GeoJSONFeature[] = [];

export const geofenceGeometryReducer = createReducer(defaultGeofenceGeometryState, handleAction => [
  handleAction(setGeofenceGeometry, (_, { payload }) => (payload !== null ? [payload] : [])),
]);

export const defaultIsBalloonShowState = false;

export const isBalloonShowReducer = createReducer(defaultIsBalloonShowState, handleAction => [
  handleAction(balloonToggle, (state, { payload }) =>
    // tslint:disable-next-line: strict-type-predicates
    typeof payload === 'boolean' ? payload : !state
  ),
]);

export const balloonDataDefaultState = {};
export const balloonDataReducer = createReducer(balloonDataDefaultState, handleAction => [
  handleAction(balloonToggle, (state, { payload, meta }) => ('data' in meta ? meta.data : state)),
]);

export const isMainPanelShowDefaultState = true;

export const isMainPanelShowReducer = createReducer(isMainPanelShowDefaultState, handleAction => [
  handleAction(mainPanelToggle, (state, { payload }) =>
    // tslint:disable-next-line: strict-type-predicates
    typeof payload === 'boolean' ? payload : !state
  ),
]);

// layersRootReducer
export default combineReducers({
  isDrawControlShow: isDrawControlShowReducer,
  geofenceGeometry: geofenceGeometryReducer,
  // drawGeometry: drawGeometryReducer,
  // regionGeometry: regionGeometryReducer,
  regionsOfInterest: regionsOfInterestReducer,
  isBalloonShow: isBalloonShowReducer,
  balloonData: balloonDataReducer,
  isMainPanelShow: isMainPanelShowReducer,
});

/**
 * Selectors
 */
export const getIsDrawControlShow = (state: RootStateType) => state.map.isDrawControlShow;
export const getGeofenceGeometry = (state: RootStateType) => state.map.geofenceGeometry;
// export const getDrawGeometry = (state: RootStateType) => state.map.drawGeometry;
// export const getRegionGeometry = (state: RootStateType) => state.map.regionGeometry;
export const getRegionsOfInterest = (state: RootStateType) => state.map.regionsOfInterest;
export const getIsBalloonShow = (state: RootStateType) => state.map.isBalloonShow;
export const getBalloonData = (state: RootStateType) => state.map.balloonData;
export const getIsMainPanelShow = (state: RootStateType) => state.map.isMainPanelShow;
