import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
// import addDays from 'date-fns/addDays';
import Tooltip from 'react-tooltip-lite';

import Accordion from '@geobank/components/src/common/Accordion/Accordion';
import ButtonTabs from '@geobank/components/src/common/ButtonTabs/ButtonTabs'; // , { IActiveButtonTab }

import cameraIcon from '@geobank/assets/src/icons/CameraIcon';
// import earthIcon from '@geobank/assets/src/icons/EarthIcon';
import folderIcon from '@geobank/assets/src/icons/FolderIcon';
import loadIcon from '@geobank/assets/src/icons/LoadIcon';
import monitorIcon from '@geobank/assets/src/icons/MonitorIcon';
import nibIcon from '@geobank/assets/src/icons/NibIcon';

import { ReactComponent as HelpIcon } from 'components/common/HelpIcon/info.svg';
import UploadShapeFile from 'components/map/UploadShapeFile/UploadShapeFile';
import { RootState } from 'ducks';
import { getIsFree } from 'ducks/authIAM';
import { drawControl, getGeofenceGeometry } from 'ducks/map';
import { changeSearchParams, defaultSearchParamsState, getSearchParams } from 'ducks/metadata';

import AdditionalOptions from './AdditionalOptions';
import CloudinessFieldset from './CloudinessFieldset';
// import DateRangeFieldset from './DateRangeFieldset';
import NewShootingModal from './NewShootingModal';
import PlatformFieldset from './PlatformFieldset';

import * as metadataTypes from 'ducks/types/metadataTypes';

import { getPlatformIds } from 'components/searchForm/PlatformTree/PlatformTree';
import { getInstrumentsById } from 'ducks/classifier';
import styles from '../LeftMenu.module.scss';
import DateRangeParam from './DateRangeParam';

const SearchForm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = useSelector((state: RootState) => getSearchParams(state));
  const isFreeUser = useSelector((state: RootState) => getIsFree(state));
  const geofence = useSelector((state: RootState) => getGeofenceGeometry(state));
  const platforms = useSelector((state: RootState) => getInstrumentsById(state));

  const uploadShapeFileRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!isFreeUser) {
      const indexAVR = searchParams.instrumentIdentifiers!.indexOf('AVR');
      if (indexAVR > -1) {
        searchParams.instrumentIdentifiers!.splice(indexAVR, 1);
      }
      const indexPANMUX = searchParams.instrumentIdentifiers!.indexOf('PANMUX');
      if (indexPANMUX > -1) {
        searchParams.instrumentIdentifiers!.splice(indexPANMUX, 1);
      }
      const indexPMS = searchParams.instrumentIdentifiers!.indexOf('PMS');
      if (indexPMS > -1) {
        searchParams.instrumentIdentifiers!.splice(indexPMS, 1);
      }
      const indexWFV = searchParams.instrumentIdentifiers!.indexOf('WFV');
      if (indexWFV > -1) {
        searchParams.instrumentIdentifiers!.splice(indexWFV, 1);
      }
      const indexLISS3 = searchParams.instrumentIdentifiers!.indexOf('LISS3');
      if (indexLISS3 > -1) {
        searchParams.instrumentIdentifiers!.splice(indexLISS3, 1);
      }
      const indexLISS4 = searchParams.instrumentIdentifiers!.indexOf('LISS4');
      if (indexLISS3 > -1) {
        searchParams.instrumentIdentifiers!.splice(indexLISS4, 1);
      }
      const indexWFI = searchParams.instrumentIdentifiers!.indexOf('WFI');
      if (indexWFI > -1) {
        searchParams.instrumentIdentifiers!.splice(indexWFI, 1);
      }
      const indexIRS = searchParams.instrumentIdentifiers!.indexOf('IRS');
      if (indexIRS > -1) {
        searchParams.instrumentIdentifiers!.splice(indexIRS, 1);
      }
      const indexMUX = searchParams.instrumentIdentifiers!.indexOf('MUX');
      if (indexMUX > -1) {
        searchParams.instrumentIdentifiers!.splice(indexMUX, 1);
      }
      const indexMUXNAD = searchParams.instrumentIdentifiers!.indexOf('MUXNAD');
      if (indexMUXNAD > -1) {
        searchParams.instrumentIdentifiers!.splice(indexMUXNAD, 1);
      }
      const indexBWD = searchParams.instrumentIdentifiers!.indexOf('BWD');
      if (indexBWD > -1) {
        searchParams.instrumentIdentifiers!.splice(indexBWD, 1);
      }
      const indexMSU101 = searchParams.instrumentIdentifiers!.indexOf('MSU101');
      if (indexMSU101 > -1) {
        searchParams.instrumentIdentifiers!.splice(indexMSU101, 1);
      }
      const indexMSU102 = searchParams.instrumentIdentifiers!.indexOf('MSU102');
      if (indexMSU102 > -1) {
        searchParams.instrumentIdentifiers!.splice(indexMSU102, 1);
      }
      const pltIds = getPlatformIds(platforms, searchParams.instrumentIdentifiers!);
      dispatch(
        changeSearchParams({
          platformTypeIdentifier: pltIds,
          instrumentIdentifiers: searchParams.instrumentIdentifiers!,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (field: string, value: any) => {
    dispatch(changeSearchParams({ [field]: value }));
  };

  const handleRegionSelectionChange = (value: string) => {
    const activeItem = value as metadataTypes.RegionSelectionType;
    dispatch(changeSearchParams({ regionSelection: activeItem }));
    if (activeItem === 'screen') {
      dispatch(drawControl.hide());
      if (geofence.length > 0) {
        drawControl.setGeometry(geofence[0]);
      }
    } else if (activeItem === 'draw') {
      dispatch(drawControl.show());
    } else if (activeItem === 'shape') {
      dispatch(drawControl.hide());
      if (uploadShapeFileRef) {
        uploadShapeFileRef.current?.click();
      }
    }
  };

  const handleSourceTypeChange = (value: string) => {
    const activeItem = value as metadataTypes.SourceType;
    if (['archive', 'esmdp2'].includes(activeItem!)) {
      const updatedSearchParams = {
        acquisitionDateAfter: undefined,
        acquisitionDateBefore: new Date(), // addDays(new Date(), -30);
        sourceType: activeItem,
        instrumentIdentifiers: defaultSearchParamsState.instrumentIdentifiers,
      };
      dispatch(changeSearchParams(updatedSearchParams));
      value === 'archive' ? history.push('/') : history.push('/digitalearth');
    } else if (activeItem === 'shooting') {
      dispatch(changeSearchParams({ modal: 'shooting' }));
      return;
    }
  };

  const handleModalClose = () => {
    dispatch(changeSearchParams({ modal: null }));
  };

  const sunHeightHandler = (value: number[]): void => {
    dispatch(
      changeSearchParams({
        illuminationElevationAngleMin: value[0],
        illuminationElevationAngleMax: value[1],
      })
    );
  };

  const angleSystemHandler = (value: number[]): void => {
    dispatch(changeSearchParams({ nadirTiltAngleMin: value[0], nadirTiltAngleMax: value[1] }));
  };
  return (
    <>
      {searchParams.modal !== null && <NewShootingModal onClose={handleModalClose} />}

      <div className={styles.div_search_option}>
        <div>
          <span className={styles.span_header_test}>
            <Trans>Район снимка</Trans>
          </span>
        </div>
        <div>
          <I18n>
            {({ i18n }) => (
              <ButtonTabs
                tabsList={[
                  {
                    label: i18n._(t`Область экрана`),
                    value: 'screen',
                    icon: monitorIcon,
                  },
                  {
                    label: i18n._(t`Указать на карте`),
                    value: 'draw',
                    icon: nibIcon,
                  },
                  {
                    label: i18n._(t`Загрузить shape`),
                    value: 'shape',
                    icon: loadIcon,
                    helper: (
                      <span>
                        <Tooltip
                          tagName="span"
                          styles={{ marginLeft: '6px' }}
                          tipContentClassName="tip-content--border-grey tip-content--width-280"
                          arrow={false}
                          background="white"
                          color="black"
                          direction="right"
                          content={
                            <Trans>
                              Поддерживаются shape-файлы c одной геометрией типа Polygon
                            </Trans>
                          }
                        >
                          <HelpIcon width={16} height={16} fill="currentColor" />
                        </Tooltip>
                      </span>
                    ),
                  },
                ]}
                activeTab={searchParams.regionSelection}
                onChange={handleRegionSelectionChange}
              />
            )}
          </I18n>
          <UploadShapeFile
            ref={uploadShapeFileRef}
            onChangeRegionSelectionType={handleRegionSelectionChange}
          />
        </div>
      </div>
      <div className={styles.div_search_option}>
        <div>
          <span className={styles.span_header_test}>
            <Trans>Источник</Trans>
          </span>
        </div>
        <div>
          <I18n>
            {({ i18n }) => (
              <ButtonTabs
                tabsList={[
                  {
                    label: i18n._(t`Снимки архива`),
                    value: 'archive',
                    icon: folderIcon,
                    helper: (
                      <Tooltip
                        tagName="span"
                        styles={{ marginLeft: '6px' }}
                        tipContentClassName="tip-content--border-grey tip-content--width-280"
                        arrow={false}
                        background="white"
                        color="black"
                        direction="right"
                        content={
                          <Trans>Найденные снимки из архива доступны для скачивания сразу</Trans>
                        }
                      >
                        <HelpIcon width={16} height={16} fill="currentColor" />
                      </Tooltip>
                    ),
                  },
                  // {
                  //   label: i18n._(t`Цифровая Земля`),
                  //   value: 'esmdp2',
                  //   icon: earthIcon,
                  // },
                  {
                    label: i18n._(t`Съемка на заказ`),
                    value: 'shooting',
                    icon: cameraIcon,
                  },
                ]}
                activeTab={searchParams.sourceType}
                onChange={handleSourceTypeChange}
              />
            )}
          </I18n>
        </div>
      </div>
      <div className={styles.div_search_option}>
        <div>
          <span className={styles.span_header_test}>
            <Trans>Диапазон дат</Trans>
          </span>
        </div>
        <div className={styles.date_input_content}>
          {/* TODO - в случае типа заявки не только выставить значения по умолчанию, но и заблокировать соотв-е даты */}
          <DateRangeParam />
        </div>
      </div>
      <div className={styles.div_search_option}>
        <PlatformFieldset
          /* onChange={data => console.log(data)} */
          checked={searchParams.instrumentIdentifiers}
          onChange={handleInputChange}
          source={searchParams.sourceType!}
        />
      </div>
      <div className={styles.div_search_option}>
        <div>
          <span className={styles.span_header_test}>
            <Trans>
              Облачность <span style={{ fontSize: '0.75em' }}>(не более)</span>
            </Trans>
          </span>
        </div>
        <div style={{ padding: '10px 10px 0' /* paddingTop: 20 */ }}>
          <CloudinessFieldset
            cloudinessMax={searchParams.cloudinessMax!}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.div_search_option} style={{ color: 'white' }}>
        <I18n>
          {({ i18n }) => (
            <Accordion label={i18n._(t`Дополнительные параметры`)} right={true}>
              <AdditionalOptions
                sunHeightHandler={sunHeightHandler}
                angleSystemHandler={angleSystemHandler}
                // sunHeightValue={sunHeight}
                sunHeightValue={[
                  searchParams.illuminationElevationAngleMin || 0,
                  searchParams.illuminationElevationAngleMax || 90,
                ]}
                // angleSystemValue={angleSystem}
                angleSystemValue={[
                  searchParams.nadirTiltAngleMin || 0,
                  searchParams.nadirTiltAngleMax || 90,
                ]}
              />
            </Accordion>
          )}
        </I18n>
      </div>
    </>
  );
};

export default SearchForm;
