// import addDays from 'date-fns/addDays';
import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import { combineReducers } from 'redux';

import { RootState as RootStateType } from './root';
// export type RootState = RootStateType;
import { createSimpleRequest } from './simpleRequest';

/**
 * Types/Interfaces
 */
// import { Metadata } from './types/metadataTypes'; // SearchMetadataParams
// TODO - temp import code, for index.ts
// tslint:disable-next-line: no-duplicate-imports
import * as metadataTypes from './types/metadataTypes'; // ImageMetadataDetail
export type ImageMetadata = metadataTypes.ImageMetadata;

/**
 * Actions
 */

export const changeSearchParams = createActionCreator(
  'METADATA/CHANGE_SEARCH_PARAMS',
  resolve => (param: metadataTypes.IParam) => resolve(param)
);

export const searchImages = {
  // METADATA_SEARCH_REQUEST
  // request: createActionCreator('METADATA_SEARCH_REQUEST', resolve => (params: object | null) =>
  request: createActionCreator(
    'CATALOG/SEARCH_REQUEST',
    resolve => (params: metadataTypes.SearchParams) => resolve(params)
  ),
  success: createActionCreator(
    'CATALOG/SEARCH_SUCCESS',
    resolve => (data: { count: number; results: ImageMetadata[] }) => resolve(data)
  ),
  // TODO - может быть лучше тут преобразовывать данные, а не в saga!?
  // success: createActionCreator('METADATA/SEARCH_SUCCESS', resolve => (data: object[]) => {
  //   console.log('METADATA/SEARCH_SUCCESS', data);
  //   return resolve(data as Metadata[]);
  // }),
  failure: createActionCreator('CATALOG/SEARCH_FAILURE', resolve => error => resolve(error)),
  clearParams: createActionCreator('CATALOG/CLEAR_PARAMS'),
};

export const fetchImageMetadata = {
  request: createActionCreator(
    'CATALOG/FETCH_IMAGE_METADATA',
    resolve => (params: metadataTypes.SearchParams & { callback?: (data: any) => void }) =>
      resolve(params)
  ),
};

// export const removeFilter = createActionCreator(
//   'METADATA/REMOVE_FILTER',
//   resolve => (data: string) => resolve(data),
// );

export const clearMetadata = createActionCreator(
  'METADATA/CLEAR_METADATA',
  resolve => (ids: Array<ImageMetadata['identifier']>) => resolve({ ids })
);

export const selectMetadataRoute = createActionCreator(
  'METADATA/SELECT_METADATA_ROUTE',
  // metadataId: number,
  resolve => (metadataId: ImageMetadata['identifier'], isZoomNeed?: boolean) =>
    resolve({ metadataId, isZoomNeed })
);

export const removeMetadataRoute = createActionCreator(
  'METADATA/REMOVE_METADATA_ROUTE',
  resolve => (metadataId: ImageMetadata['identifier']) => resolve({ metadataId })
);

/* export const toggleMetadataRouteContour = createActionCreator(
  'METADATA/TOGGLE_METADATA_ROUTE_CONTOUR',
  resolve => (metadataId: ImageMetadata['id']) => resolve({ metadataId })
  // resolve => (metadataId: number | number[]) => resolve(Array.isArray(metadataIds) ? metadataIds : { metadataId: metadataIds })
); */
export const toggleImageContour = {
  request: createActionCreator(
    'IMAGES/TOGGLE_IMAGE_CONTOUR_REQUEST',
    resolve => (ids: Array<ImageMetadata['identifier']>) => resolve({ ids })
  ),
  success: createActionCreator(
    'IMAGES/TOGGLE_IMAGE_CONTOUR_SUCCESS',
    resolve => (ids: Array<ImageMetadata['identifier']>, flag: boolean = true) =>
      resolve({
        ids,
        flag,
      })
  ),
};

// metadataRoutePreviewAction
// export const toggleMetadataRoutePreview = createActionCreator(
//   'METADATA/TOGGLE_METADATA_ROUTE_PREVIEW',
//   resolve => (metadataId: ImageMetadata['id']) => resolve({ metadataId })
// );
/* export const toggleImagePreview = createActionCreator(
  'IMAGES/TOGGLE_IMAGE_PREVIEW',
  resolve => (ids: ImageMetadata['id'][], flag: boolean = true) => resolve({ ids, flag })
); */
export const toggleImagePreview = {
  request: createActionCreator(
    'IMAGES/TOGGLE_IMAGE_PREVIEW_REQUEST',
    resolve => (ids: Array<ImageMetadata['identifier']>) => resolve({ ids })
  ),
  success: createActionCreator(
    'IMAGES/TOGGLE_IMAGE_PREVIEW_SUCCESS',
    resolve => (ids: Array<ImageMetadata['identifier']>, flag: boolean = true) =>
      resolve({
        ids,
        flag,
      })
  ),
};
// export const metadataRoutePreview = {
//   // SHOW_DRAW_CONTROL, HIDE_DRAW_CONTROL
//   show: createActionCreator(
//     'METADATA/SHOW_METADATA_ROUTE_PREVIEW',
//     resolve => (metadataId: number) => resolve({ metadataId }),
//   ),
//   hide: createActionCreator(
//     'METADATA/HIDE_METADATA_ROUTE_PREVIEW',
//     resolve => (metadataId: number) => resolve({ metadataId }),
//   ),
// };

// export const toggleViewFullImage = createActionCreator(
//   'METADATA/TOGGLE_VIEW_FULL_IMAGE',
//   resolve => (metadataId: ImageMetadata['id']) => resolve({ metadataId })
// );
export const toggleImageFullRes = {
  request: createActionCreator(
    'IMAGES/TOGGLE_IMAGE_FULL_RES_REQUEST',
    resolve => (ids: Array<ImageMetadata['identifier']>) => resolve(ids)
  ),
  success: createActionCreator(
    'IMAGES/TOGGLE_IMAGE_FULL_RES_SUCCESS',
    resolve => (ids: Array<ImageMetadata['identifier']>, flag: boolean = true) =>
      resolve({ ids, flag })
  ),
};

export const toggleCheckImage = createActionCreator(
  'METADATA/TOGGLE_CHECK_IMAGE',
  // resolve => (metadataId: number) => resolve({ metadataId })
  resolve => (metadataIds: ImageMetadata['identifier'] | Array<ImageMetadata['identifier']>) =>
    resolve(metadataIds)
);

export const changeSourceType = createActionCreator(
  'METADATA/CHANGE_SOURCE_TYPE',
  resolve => (sourceType: metadataTypes.SourceType) => resolve(sourceType)
);

export const changeRegionSelectionType = createActionCreator(
  'METADATA/CHANGE_REGION_SELECTION',
  resolve => (regionSelectionType: metadataTypes.RegionSelectionType) =>
    resolve(regionSelectionType)
);

export const changePlatformType = createActionCreator(
  'METADATA/CHANGE_PLATFORM_TYPE',
  resolve => (platformType: string[]) => resolve(platformType)
);

/**
 * Reducers and state
 */
// export type MetadataState = ImageMetadata[];

export const defaultMetadataState: ImageMetadata[] = [];

export const metadataReducer = createReducer(defaultMetadataState, handleAction => [
  handleAction(searchImages.success, (_, { payload }) => payload.results),
  // handleAction(addTodo.complete, (state, { payload }) => [...state, payload]),
  handleAction(removeMetadataRoute, (state, { payload }) =>
    state.filter(md => md.identifier !== payload.metadataId)
  ),
  handleAction(clearMetadata, () => defaultMetadataState),
  // handleAction(toggleImagePreview, (state, { payload }) => {
  //   const { ids, flag } = payload;
  //   const result = [...state];
  //   for (let i = 0; i < ids.length; i++) {
  //     let imageIndex = 1;
  //   }
  //   return state;
  // }),
]);

export const totalImages: number = 0;
export const totalImagesReducer = createReducer(totalImages, handleAction => [
  handleAction(searchImages.success, (_, { payload }) => payload.count),
  handleAction(clearMetadata, () => totalImages),
  // handleAction(removeMetadataRoute, state => (state > 0 ? state - 1 : 0)),
]);

export const defaultIsFetchingState = false;

export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
  handleAction(searchImages.request, () => true),
  handleAction([searchImages.success, searchImages.failure], () => false),
  handleAction(clearMetadata, () => defaultIsFetchingState),
]);

// NOTE! - При добавлении или убирании параметра нужно править SearchResult (динамически считается высота)
// export const defaultSearchParamsState: any = {}; // SearchMetadataParams
export const defaultSearchParamsState: metadataTypes.SearchParams = {
  modal: null,
  regionSelection: 'screen',
  imageType: 'optic',
  sourceType: 'archive',
  archiveObjectType: 'delivery',
  invalidParams: [],
  // Common metadata params
  ids: '',
  acquisitionDateAfter: undefined,
  acquisitionDateBefore: new Date(),
  offset: 0,
  limit: 100,
  // Optic metadata params
  cloudinessMax: 50,
  illuminationElevationAngleMin: undefined,
  illuminationElevationAngleMax: undefined,
  instrumentIdentifiers: ['MSS', 'AVR'],
  platformTypeIdentifier: ['AI', 'KV'],
  nadirTiltAngleMin: undefined,
  nadirTiltAngleMax: undefined,
  // TODO: Проверить передачу параметра processingLevelCode
  processingLevelCode: ['L0', 'L1A', 'L2', 'L2A', 'L1B'],
  // Radar metadata params
  datetime: `../${new Date().toISOString()}`,
  sarInstrumentModes: ['SPOT'],
  sarObservationDirection: undefined,
  satOrbitState: undefined,
  sarPolarizations: undefined,
  viewIncidenceAngle: undefined,
};

export const searchParamsReducer = createReducer(defaultSearchParamsState, handleAction => [
  handleAction(changeSearchParams, (state, { payload }) => {
    return { ...state, ...payload };
  }),
  handleAction(searchImages.request, (_, { payload }) => payload),
  handleAction(searchImages.clearParams, () => ({ ...defaultSearchParamsState })),
  handleAction(createSimpleRequest.success, () => ({ ...defaultSearchParamsState })),
]);

export const defaultSelectedMetadataRouteState = null as ImageMetadata['identifier'] | null; // null as number | null;
export const selectedMetadataRouteReducer = createReducer(
  defaultSelectedMetadataRouteState,
  handleAction => [
    handleAction(selectMetadataRoute, (_, { payload }) => payload.metadataId),
    handleAction(searchImages.request, () => null),
    handleAction(clearMetadata, () => defaultSelectedMetadataRouteState),
  ]
);

const toggleImageFooterButtons = (
  state: string[],
  payload: { ids: string[]; flag: boolean }
): string[] => {
  const { ids, flag } = payload;
  const result: string[] = [...state];
  for (const id of ids) {
    const itemIndex = result.indexOf(id);
    if (flag && itemIndex === -1) {
      result.push(id);
    } else if (!flag && itemIndex > -1) {
      result.splice(itemIndex, 1);
    }
  }
  return result;
};

// TODO - использую сейчас как exclude массив
export const activeImageContoursDefaultState: string[] = [];
export const activeImageContoursReducer = createReducer(
  activeImageContoursDefaultState,
  handleAction => [
    handleAction(toggleImageContour.success, (state, { payload }) => {
      return toggleImageFooterButtons(state, payload);
    }),
    handleAction(searchImages.request, (state, { payload }) => (payload.offset === 0 ? [] : state)),
    handleAction(clearMetadata, () => activeImageContoursDefaultState),
  ]
);

export const activeImagePreviewsDefaultState: string[] = [];
export const activeImagePreviewsReducer = createReducer(
  activeImagePreviewsDefaultState,
  handleAction => [
    handleAction(toggleImagePreview.success, (state, { payload }) => {
      return toggleImageFooterButtons(state, payload);
    }),
    handleAction(searchImages.request, (state, { payload }) => (payload.offset === 0 ? [] : state)),
    handleAction(clearMetadata, () => activeImagePreviewsDefaultState),
  ]
);

export const activeImageFullResolutionsDefaultState: string[] = [];
export const activeImageFullResolutionsReducer = createReducer(
  activeImageFullResolutionsDefaultState,
  handleAction => [
    handleAction(toggleImageFullRes.success, (state, { payload }) => {
      return toggleImageFooterButtons(state, payload);
    }),
    handleAction(searchImages.request, (state, { payload }) => (payload.offset === 0 ? [] : state)),
    handleAction(clearMetadata, () => activeImageFullResolutionsDefaultState),
  ]
);

export const checkedImages: string[] = [];
export const checkedImagesReducer = createReducer(checkedImages, handleAction => [
  handleAction(toggleCheckImage, (state, { payload }) => {
    if (Array.isArray(payload)) {
      return payload.map(id => id);
    }

    const metadataId = payload; // payload.metadataId;
    const imageIndex = state.indexOf(metadataId);
    const result: string[] = [...state];
    if (imageIndex > -1) {
      result.splice(imageIndex, 1);
    } else {
      result.push(metadataId);
    }
    return result;
  }),
  // при удалении снимка "снимаем галку"
  handleAction(removeMetadataRoute, (state, { payload }) =>
    state.filter(id => id !== payload.metadataId)
  ),
  // может быть сохранять отмеченные при новом поиске?
  // handleAction(searchImages.success, () => []),
  handleAction(searchImages.request, (state, { payload }) => (payload.offset === 0 ? [] : state)),
  handleAction(clearMetadata, () => checkedImages),
]);

// metadataRootReducer
export default combineReducers({
  activeImageContours: activeImageContoursReducer,
  activeImagePreviews: activeImagePreviewsReducer,
  activeImageFullResolutions: activeImageFullResolutionsReducer,
  checkedImages: checkedImagesReducer,
  isFetching: isFetchingReducer,
  results: metadataReducer,
  searchParams: searchParamsReducer,
  selectedMetadataRoute: selectedMetadataRouteReducer,
  totalImages: totalImagesReducer,
});

/**
 * Selectors
 */
export const getMetadata = (state: RootStateType) => state.metadata.results;
export const getTotalImages = (state: RootStateType) => state.metadata.totalImages;
export const getIsFetching = (state: RootStateType) => state.metadata.isFetching;
export const getSearchParams = (state: RootStateType) => state.metadata.searchParams;
export const getSelectedMetadataRoute = (state: RootStateType) =>
  state.metadata.selectedMetadataRoute;
export const getActiveImageContours = (state: RootStateType) => state.metadata.activeImageContours;
export const getActiveImagePreviews = (state: RootStateType) => state.metadata.activeImagePreviews;
export const getCheckedImages = (state: RootStateType) => state.metadata.checkedImages;
export const getActiveImageFullResolutions = (state: RootStateType) =>
  state.metadata.activeImageFullResolutions;
export const getImageSourceType = (state: RootStateType) => state.metadata.searchParams.sourceType;
