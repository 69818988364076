import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t } from '@lingui/macro'; // Trans
import { I18n } from '@lingui/react';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getIsAuthenticated } from 'ducks/authIAM';
import { getIsMainPanelShow, mainPanelToggle } from 'ducks/map';
import { getIsFetching, getResults, searchObjects, SearchResultItem } from 'ducks/mapObjects'; // SearchResultItem,

import ExpandIcon from '@geobank/assets/src/icons/ExpandIcon';
import SearchInput from '@geobank/components/src/forms/SearchInput/SearchInput';
// import AuthButton from 'components/map/AuthButton/AuthButton';
import AuthButtonIAM from 'components/map/AuthButton/AuthButtonIAM';
// import Button from '@geobank/components/src/common/Button/Button';
import LangSelector from 'components/map/LangSelector/LangSelector';
import MainMenu from 'components/map/MainMenu/MainMenu';
import UserMenu from 'components/map/UserMenu/UserMenu';
// import LogoDemo from './LogoDemo';

import { getSelectedBaseLayerId } from '../../../ducks/mapLayers';
import styles from './TransparentHeader.module.scss';

interface TransparentHeaderProps {
  search?: boolean;
}

const TransparentHeader: React.FC<TransparentHeaderProps & RouteComponentProps<{}>> = props => {
  const { search } = props;
  const dispatch = useDispatch();
  const searchInputRef = React.useRef<SearchInput>(null);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isFetching = useSelector(getIsFetching);
  const results = useSelector(getResults);
  const selectedBaseLayerId = useSelector(getSelectedBaseLayerId);
  React.useEffect(() => {
    searchInputRef.current?.handleClear();
  }, [selectedBaseLayerId]);
  // searchInput
  const isMainPanelShow = useSelector(getIsMainPanelShow);

  const handleMainPanelToggle = () => {
    dispatch(mainPanelToggle());
  };

  const handleSearchRequest = (value: string) => {
    dispatch(searchObjects.request(value));
  };

  const handleSearchObjectsClear = () => {
    dispatch(searchObjects.clear());
  };

  const handleSearchObjectsSelect = (value: SearchResultItem) => {
    dispatch(searchObjects.select(value));
  };

  return (
    <div>
      {search === true && (
        <I18n>
          {({ i18n }) => (
            <div className={styles.mapLeftSection}>
              <div
                title={`${
                  selectedBaseLayerId === 'Yandex' ? 'Поиск Яндекс' : 'Поиск Openstreetmap'
                }`}
              >
                <SearchInput
                  ref={searchInputRef}
                  placeholder={i18n._(t`Название, адрес или координаты на карте`)}
                  isLoading={isFetching}
                  results={results}
                  onSearchChange={handleSearchRequest}
                  onResultSelect={handleSearchObjectsSelect}
                  onClear={handleSearchObjectsClear}
                />
              </div>
              <div className={styles.closePanelWrap}>
                <span
                  title={
                    isMainPanelShow
                      ? i18n._(t`Закрыть основную панель`)
                      : i18n._(t`Открыть основную панель`)
                  }
                  className={classNames(styles.closePanelBtn, {
                    [styles.closePanelBtnShow]: isMainPanelShow,
                  })}
                  onClick={handleMainPanelToggle}
                >
                  <ExpandIcon />
                </span>
              </div>
            </div>
          )}
        </I18n>
      )}

      {/* <div className={styles.mapLogo}>
        <LogoDemo />
      </div> */}

      {isAuthenticated ? (
        // <div className={styles.mapMainGroupTest}>
        <div className={styles.mapMainGroup}>
          {/* <Button
            onClick={() => {
              // alert('multi request');
              history.push('/requests/new');
            }}
            height="45px"
            disabled
            title="в разработке ..."
          >
            <Trans>Мультирайонная заявка</Trans>
          </Button> */}
          <LangSelector className={styles.marginLeft} />
          <UserMenu className={styles.marginLeft} />
          <MainMenu className={styles.marginLeft} />
        </div>
      ) : (
        <div className={styles.mapMainGroup}>
          <LangSelector className={styles.marginLeft} />
          <AuthButtonIAM className={styles.marginLeft} />
          <MainMenu className={styles.marginLeft} />
        </div>
      )}
    </div>
  );
};

export default withRouter(TransparentHeader);
