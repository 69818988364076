import { MousePosition } from 'ol/control';
import { toStringHDMS, toStringXY } from 'ol/coordinate';

export const hdmsCoordinateFormat = (coord: any) => toStringHDMS(coord);
export const xyCoordinateFormat = (coord: any) => toStringXY(coord, 4).replace(/\./g, ',');

class CoordinateControl extends MousePosition {
  constructor(optOptions: any) {
    const options = optOptions || {};
    super(options);
    this.element.setAttribute('id', 'coordinateValue');
    this.element.addEventListener('click', event => {
      const currentCoordinateFormat = this.getCoordinateFormat();
      currentCoordinateFormat === hdmsCoordinateFormat
        ? this.setCoordinateFormat(xyCoordinateFormat)
        : this.setCoordinateFormat(hdmsCoordinateFormat);
      this.handleMouseMove(event);
    });
  }
}

export default CoordinateControl;
