import React from 'react';

import { Trans } from '@lingui/macro';
import { i18nMark } from '@lingui/react';
import { formatArea, readFilesAsArrayBuffer } from 'components/map/Map/utils';
import { genId } from 'components/utils/identifier';
import { isValidGeofenceIntersection } from 'components/utils/validation/checkIntersections';
import * as store from 'ducks';
import { balloonToggle, getGeofenceGeometry, setShapeGeometry } from 'ducks/map';
import { notifyActions } from 'ducks/message';
import GeoJSON, { GeoJSONFeature } from 'ol/format/GeoJSON';
import { connect } from 'react-redux';
import * as shapefile from 'shapefile';
import GeoportalMap from '../Map/Map';

interface IShapefileFeatures {
  sourceFeature: GeoJSONFeature;
  projectedFeature: GeoJSONFeature;
}

interface UploadShapeFileProps {
  geofence: any;
  setShapefileGeometry: typeof setShapeGeometry;
  pushMessage: typeof notifyActions.push;
  addSubscriptionBalloonToggle: typeof balloonToggle;
  onChangeRegionSelectionType: (value: string) => void;
}

export type Ref = HTMLInputElement;

const UploadShapeFile = React.forwardRef<Ref, UploadShapeFileProps>((props, ref) => {
  const {
    geofence,
    pushMessage,
    setShapefileGeometry,
    addSubscriptionBalloonToggle,
    onChangeRegionSelectionType,
  } = props;

  const handleImageChange = (e: any) => {
    e.preventDefault();
    let shpFile;
    let dbfFile;
    for (const file of e.target.files) {
      if (file.name.toLowerCase().endsWith('.shp')) {
        shpFile = file;
      } else if (file.name.toLowerCase().endsWith('.dbf')) {
        dbfFile = file;
      }
    }

    const features: IShapefileFeatures[] = [];
    readFilesAsArrayBuffer([shpFile, dbfFile])
      .then(resultFiles => {
        const options = { encoding: 'UTF-8' }; // { encoding: 'windows-1251' };
        const [shpFileArrayBuffer, dbfFileArrayBuffer] = resultFiles;
        if (!shpFileArrayBuffer) {
          throw new Error('Shape file not selected!!!');
        }
        if (dbfFileArrayBuffer) {
          return shapefile.open(shpFileArrayBuffer, dbfFileArrayBuffer, options);
        } else {
          return shapefile.open(shpFileArrayBuffer, undefined, options);
        }
      })
      .then(source =>
        source.read().then(function cb(result): any {
          if (result.done) {
            if (features.length === 1 && features[0].sourceFeature.geometry.type === 'Polygon') {
              // && features[0].sourceFeature.geometry.coordinates.length === 1) {
              // ? From DrawControl
              let geometryIntersection: boolean = true;
              // const geometryIntersection =
              if (geofence.length !== 0) {
                geometryIntersection = isValidGeofenceIntersection(
                  features[0].sourceFeature.geometry.coordinates,
                  geofence[0].geometry.coordinates
                );
              }
              if (geometryIntersection) {
                setShapefileGeometry(features[0].sourceFeature, true);
                addSubscriptionBalloonToggle(true);
              } else {
                setShapefileGeometry(geofence[0], true);
                pushMessage({
                  timeout: 5000,
                  message: i18nMark(
                    'Геометрия выбранного shape файла выходит за пределы ограничивающего полигона!'
                  ),
                  place: 'bc',
                  color: 'alert',
                });
                // ? From DrawControl
              }
            } else {
              pushMessage({
                message: (
                  <span>
                    <Trans>
                      Не поддерживаются shape-файлы с геометрией типа MultiPolygon и набор из
                      нескольких геометрий
                    </Trans>
                  </span>
                ),
                color: 'alert',
                timeout: 5000,
              });
            }
            addSubscriptionBalloonToggle(false);
            return;
          }
          const format = new GeoJSON();
          const sourceFeature = GeoportalMap.getInstance().readGeometry(
            result.value,
            'GeoJSON',
            false
          );
          const projectedFeature = GeoportalMap.getInstance().readGeometry(result.value, 'GeoJSON');
          projectedFeature.setId(genId());
          const { output, measure } = formatArea(projectedFeature.getGeometry());
          // sourceFeature.set('area': output, 'measure': measure);
          sourceFeature.set('area', output);
          sourceFeature.set('measure', measure);
          const projectedGeoJSON = format.writeFeatureObject(projectedFeature);
          const sourceGeoJSON = format.writeFeatureObject(sourceFeature);
          features.push({ sourceFeature: sourceGeoJSON, projectedFeature: projectedGeoJSON });
          addSubscriptionBalloonToggle(true);
          onChangeRegionSelectionType('draw');
          return source.read().then(cb);
        })
      )
      .catch(err => console.error(err));
  };

  return (
    <div style={{ display: 'none' }}>
      <form>
        <input
          className="fileInput"
          ref={ref}
          type="file"
          multiple
          accept=".shp"
          onChange={e => handleImageChange(e)}
        />
      </form>
    </div>
  );
});

export default connect(
  (state: store.RootState) => ({
    geofence: getGeofenceGeometry(state),
  }),
  {
    setShapefileGeometry: setShapeGeometry,
    addSubscriptionBalloonToggle: balloonToggle,
    pushMessage: notifyActions.push,
  },
  null,
  { forwardRef: true }
)(UploadShapeFile);
